/* tslint:disable */
/* eslint-disable */
/**
 * Mjøsen Skog skogbruksleder API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { GmModelLibraryModelsEnvironmentalAgreementModel } from '../model';
/**
 * EnvironmentalAgreementApi - axios parameter creator
 * @export
 */
export const EnvironmentalAgreementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new agreement
         * @param {GmModelLibraryModelsEnvironmentalAgreementModel} [gmModelLibraryModelsEnvironmentalAgreementModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentalAgreementCreateEnvironmentalAgreement: async (gmModelLibraryModelsEnvironmentalAgreementModel?: GmModelLibraryModelsEnvironmentalAgreementModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/environmentalAgreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmModelLibraryModelsEnvironmentalAgreementModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create agreement pdf
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentalAgreementCreateEnvironmentalAgreementPdf: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('environmentalAgreementCreateEnvironmentalAgreementPdf', 'id', id)
            const localVarPath = `/v1/environmentalAgreements/public/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get environmental agreement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentalAgreementGetEnvironmentalAgreement: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('environmentalAgreementGetEnvironmentalAgreement', 'id', id)
            const localVarPath = `/v1/environmentalAgreements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate and get agreement pdf
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentalAgreementGetEnvironmentalAgreementPdf: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('environmentalAgreementGetEnvironmentalAgreementPdf', 'id', id)
            const localVarPath = `/v1/environmentalAgreements/{id}/pdfdebug`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate and get agreement pdf with signatures
         * @param {string} id 
         * @param {GmModelLibraryModelsEnvironmentalAgreementModel} [gmModelLibraryModelsEnvironmentalAgreementModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentalAgreementGetEnvironmentalAgreementPdfFull: async (id: string, gmModelLibraryModelsEnvironmentalAgreementModel?: GmModelLibraryModelsEnvironmentalAgreementModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('environmentalAgreementGetEnvironmentalAgreementPdfFull', 'id', id)
            const localVarPath = `/v1/environmentalAgreements/{id}/pdfdebugfull`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmModelLibraryModelsEnvironmentalAgreementModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agreement
         * @param {string} id 
         * @param {GmModelLibraryModelsEnvironmentalAgreementModel} [gmModelLibraryModelsEnvironmentalAgreementModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentalAgreementUpdateEnvironmentalAgreement: async (id: string, gmModelLibraryModelsEnvironmentalAgreementModel?: GmModelLibraryModelsEnvironmentalAgreementModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('environmentalAgreementUpdateEnvironmentalAgreement', 'id', id)
            const localVarPath = `/v1/environmentalAgreements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmModelLibraryModelsEnvironmentalAgreementModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnvironmentalAgreementApi - functional programming interface
 * @export
 */
export const EnvironmentalAgreementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnvironmentalAgreementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new agreement
         * @param {GmModelLibraryModelsEnvironmentalAgreementModel} [gmModelLibraryModelsEnvironmentalAgreementModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async environmentalAgreementCreateEnvironmentalAgreement(gmModelLibraryModelsEnvironmentalAgreementModel?: GmModelLibraryModelsEnvironmentalAgreementModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsEnvironmentalAgreementModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.environmentalAgreementCreateEnvironmentalAgreement(gmModelLibraryModelsEnvironmentalAgreementModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvironmentalAgreementApi.environmentalAgreementCreateEnvironmentalAgreement']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create agreement pdf
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async environmentalAgreementCreateEnvironmentalAgreementPdf(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.environmentalAgreementCreateEnvironmentalAgreementPdf(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvironmentalAgreementApi.environmentalAgreementCreateEnvironmentalAgreementPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get environmental agreement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async environmentalAgreementGetEnvironmentalAgreement(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsEnvironmentalAgreementModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.environmentalAgreementGetEnvironmentalAgreement(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvironmentalAgreementApi.environmentalAgreementGetEnvironmentalAgreement']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generate and get agreement pdf
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async environmentalAgreementGetEnvironmentalAgreementPdf(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.environmentalAgreementGetEnvironmentalAgreementPdf(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvironmentalAgreementApi.environmentalAgreementGetEnvironmentalAgreementPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generate and get agreement pdf with signatures
         * @param {string} id 
         * @param {GmModelLibraryModelsEnvironmentalAgreementModel} [gmModelLibraryModelsEnvironmentalAgreementModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async environmentalAgreementGetEnvironmentalAgreementPdfFull(id: string, gmModelLibraryModelsEnvironmentalAgreementModel?: GmModelLibraryModelsEnvironmentalAgreementModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.environmentalAgreementGetEnvironmentalAgreementPdfFull(id, gmModelLibraryModelsEnvironmentalAgreementModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvironmentalAgreementApi.environmentalAgreementGetEnvironmentalAgreementPdfFull']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update agreement
         * @param {string} id 
         * @param {GmModelLibraryModelsEnvironmentalAgreementModel} [gmModelLibraryModelsEnvironmentalAgreementModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async environmentalAgreementUpdateEnvironmentalAgreement(id: string, gmModelLibraryModelsEnvironmentalAgreementModel?: GmModelLibraryModelsEnvironmentalAgreementModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsEnvironmentalAgreementModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.environmentalAgreementUpdateEnvironmentalAgreement(id, gmModelLibraryModelsEnvironmentalAgreementModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvironmentalAgreementApi.environmentalAgreementUpdateEnvironmentalAgreement']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EnvironmentalAgreementApi - factory interface
 * @export
 */
export const EnvironmentalAgreementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnvironmentalAgreementApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new agreement
         * @param {GmModelLibraryModelsEnvironmentalAgreementModel} [gmModelLibraryModelsEnvironmentalAgreementModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentalAgreementCreateEnvironmentalAgreement(gmModelLibraryModelsEnvironmentalAgreementModel?: GmModelLibraryModelsEnvironmentalAgreementModel, options?: any): AxiosPromise<GmModelLibraryModelsEnvironmentalAgreementModel> {
            return localVarFp.environmentalAgreementCreateEnvironmentalAgreement(gmModelLibraryModelsEnvironmentalAgreementModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create agreement pdf
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentalAgreementCreateEnvironmentalAgreementPdf(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.environmentalAgreementCreateEnvironmentalAgreementPdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get environmental agreement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentalAgreementGetEnvironmentalAgreement(id: string, options?: any): AxiosPromise<GmModelLibraryModelsEnvironmentalAgreementModel> {
            return localVarFp.environmentalAgreementGetEnvironmentalAgreement(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate and get agreement pdf
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentalAgreementGetEnvironmentalAgreementPdf(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.environmentalAgreementGetEnvironmentalAgreementPdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate and get agreement pdf with signatures
         * @param {string} id 
         * @param {GmModelLibraryModelsEnvironmentalAgreementModel} [gmModelLibraryModelsEnvironmentalAgreementModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentalAgreementGetEnvironmentalAgreementPdfFull(id: string, gmModelLibraryModelsEnvironmentalAgreementModel?: GmModelLibraryModelsEnvironmentalAgreementModel, options?: any): AxiosPromise<void> {
            return localVarFp.environmentalAgreementGetEnvironmentalAgreementPdfFull(id, gmModelLibraryModelsEnvironmentalAgreementModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agreement
         * @param {string} id 
         * @param {GmModelLibraryModelsEnvironmentalAgreementModel} [gmModelLibraryModelsEnvironmentalAgreementModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentalAgreementUpdateEnvironmentalAgreement(id: string, gmModelLibraryModelsEnvironmentalAgreementModel?: GmModelLibraryModelsEnvironmentalAgreementModel, options?: any): AxiosPromise<GmModelLibraryModelsEnvironmentalAgreementModel> {
            return localVarFp.environmentalAgreementUpdateEnvironmentalAgreement(id, gmModelLibraryModelsEnvironmentalAgreementModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnvironmentalAgreementApi - object-oriented interface
 * @export
 * @class EnvironmentalAgreementApi
 * @extends {BaseAPI}
 */
export class EnvironmentalAgreementApi extends BaseAPI {
    /**
     * 
     * @summary Create new agreement
     * @param {GmModelLibraryModelsEnvironmentalAgreementModel} [gmModelLibraryModelsEnvironmentalAgreementModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentalAgreementApi
     */
    public environmentalAgreementCreateEnvironmentalAgreement(gmModelLibraryModelsEnvironmentalAgreementModel?: GmModelLibraryModelsEnvironmentalAgreementModel, options?: RawAxiosRequestConfig) {
        return EnvironmentalAgreementApiFp(this.configuration).environmentalAgreementCreateEnvironmentalAgreement(gmModelLibraryModelsEnvironmentalAgreementModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create agreement pdf
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentalAgreementApi
     */
    public environmentalAgreementCreateEnvironmentalAgreementPdf(id: string, options?: RawAxiosRequestConfig) {
        return EnvironmentalAgreementApiFp(this.configuration).environmentalAgreementCreateEnvironmentalAgreementPdf(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get environmental agreement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentalAgreementApi
     */
    public environmentalAgreementGetEnvironmentalAgreement(id: string, options?: RawAxiosRequestConfig) {
        return EnvironmentalAgreementApiFp(this.configuration).environmentalAgreementGetEnvironmentalAgreement(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate and get agreement pdf
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentalAgreementApi
     */
    public environmentalAgreementGetEnvironmentalAgreementPdf(id: string, options?: RawAxiosRequestConfig) {
        return EnvironmentalAgreementApiFp(this.configuration).environmentalAgreementGetEnvironmentalAgreementPdf(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate and get agreement pdf with signatures
     * @param {string} id 
     * @param {GmModelLibraryModelsEnvironmentalAgreementModel} [gmModelLibraryModelsEnvironmentalAgreementModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentalAgreementApi
     */
    public environmentalAgreementGetEnvironmentalAgreementPdfFull(id: string, gmModelLibraryModelsEnvironmentalAgreementModel?: GmModelLibraryModelsEnvironmentalAgreementModel, options?: RawAxiosRequestConfig) {
        return EnvironmentalAgreementApiFp(this.configuration).environmentalAgreementGetEnvironmentalAgreementPdfFull(id, gmModelLibraryModelsEnvironmentalAgreementModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agreement
     * @param {string} id 
     * @param {GmModelLibraryModelsEnvironmentalAgreementModel} [gmModelLibraryModelsEnvironmentalAgreementModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentalAgreementApi
     */
    public environmentalAgreementUpdateEnvironmentalAgreement(id: string, gmModelLibraryModelsEnvironmentalAgreementModel?: GmModelLibraryModelsEnvironmentalAgreementModel, options?: RawAxiosRequestConfig) {
        return EnvironmentalAgreementApiFp(this.configuration).environmentalAgreementUpdateEnvironmentalAgreement(id, gmModelLibraryModelsEnvironmentalAgreementModel, options).then((request) => request(this.axios, this.basePath));
    }
}

