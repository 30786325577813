
import { useLocalstorage } from '../localStorage/store'
import { useCustomersStore } from '../customers/store'
import { useUserStore } from '../user/store'
import {cloneDeep} from "lodash";
import type {Property} from "~/models/customers";
import {PropertyApi} from "~/gen/openapi/sblService";
import {useAxiosClient} from "~/use/axios/client";

const { persist } = useLocalstorage()

const state = reactive({
  currentProperty: persist('currentProperty', {}) as Property
})

const currentProperty = computed<Property>(() => {
  return state.currentProperty
})

const setCurrentProperty = (property: Property): void => {
  state.currentProperty = property
}
const clearCurrentProperty = (): void => {
  state.currentProperty = {}
}

const updateProperty = async (propertyId: string, ownerId: string): Promise<void> => {
  const { currentCustomer, saveCurrentCustomerLocally } = useCustomersStore()
  const { currentUser } = useUserStore()
  const { axiosClient } = useAxiosClient()
  const propertyApi = new PropertyApi(undefined, '', axiosClient.value)

  // Until we get proper data returned we can only add current user
  if (currentUser.value?.Id !== ownerId || !currentCustomer.value?.Properties?.find(property => property.Id === propertyId)) {
    return
  }

  const customerClone = cloneDeep(currentCustomer.value)
  const propertyIndex = customerClone?.Properties?.findIndex(property => property.Id === propertyId)

  if (propertyIndex && customerClone.Properties && customerClone.Properties?.length >= propertyIndex){
    customerClone.Properties[propertyIndex].PropertyOwner = {
      Id: ownerId,
      Name: currentUser.value.Name
    }
  }

  await propertyApi.propertyUpdateProperty(propertyId, ownerId)

  saveCurrentCustomerLocally(customerClone)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePropertiesStore = () => ({
  currentProperty,
  setCurrentProperty,
  clearCurrentProperty,
  updateProperty
})
