import { useDb } from './setup'
import type {QueueItem} from '~/models/queue';
import cloneDeep from 'lodash/cloneDeep';

const { dbPromise } = useDb()

const insertQueueItems = async (queueItems: QueueItem[]): Promise<boolean> => {
  try {
    // (await dbPromise).clear('contracts')
    const tx = (await dbPromise).transaction('queue', 'readwrite')
    queueItems.forEach((queueItem: QueueItem) => {
      tx.store.put(queueItem)
    })
    await tx.done
    return true
  } catch (error) {
    console.error('insert QueueItem error', error)
    return false
  }
}

const queueDb = {
  async getById (id: string): Promise<QueueItem> {
    try {
      return (await dbPromise).get('queue', id)
    } catch (error) {
      console.error('queueDb: getById', error)
    }
  },
  async getAll (): Promise<QueueItem[]> {
    try {
      return (await dbPromise).getAll('queue')
    } catch (error) {
      console.error('queueDb: getAll', error)
    }
  },
  async getKeys (): Promise<IDBValidKey[]> {
    try {
      return (await dbPromise).getAllKeys('queue')
    } catch (error) {
      console.error('queueDb: getKeys', error)
    }
  },
  async setById (item: QueueItem): Promise<IDBValidKey> {
    try {
      return (await dbPromise).put('queue', cloneDeep(item))
    } catch (error) {
      console.error('queueDb: setById', error)
    }
  },
  async delete (id: QueueItem['Id']): Promise<void> {
    try {
      return (await dbPromise).delete('queue', id)
    } catch (error) {
      console.error('queueDb: delete', error)
    }
  },
  async clear (): Promise<void> {
    try {
      return (await dbPromise).clear('queue')
    } catch (error) {
      console.error('queueDb: clear', error)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQueueDb = () => ({
  queueDb,
  insertQueueItems
})
