export const LOGGING_PERIODS = [
  {
    text: 'Vinter',
    key: 'winter',
    months: [1, 2, 3, 12],
    value: 1
  },
  {
    text: 'Vår',
    key: 'spring',
    months: [4, 5],
    value: 2
  },
  {
    text: 'Sommer',
    key: 'summer',
    months: [6, 7, 8, 9],
    value: 3
  },
  {
    text: 'Høst',
    key: 'fall',
    months: [10, 11],
    value: 4
  }
]
