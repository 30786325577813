// https://nuxt.com/docs/examples/advanced/error-handling
// Custom error handler plugin

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hook("app:error", (error) => {
        console.log('app:error', error)
        const reload_error_list = [
            "error loading dynamically imported module",
            "Importing a module script failed",
            "Failed to fetch dynamically imported module",
        ];
        for (const message of reload_error_list) {
            if (error.message.indexOf(message) > -1) {
                console.log('Dynamically imported module error', error)
                // TODO: Handle error
                // This error seem to be related to new version replacing chunks.
            }
        }
    });

    nuxtApp.hook('vue:error', (error, instance, info) => {
        console.log('vue:error', error)
        // if (process.client) {
        //   handle client side
        // }
    })

    // Global error handler
    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
        console.log('global error handler', error)
        // if (process.client) {
        //   handle client side
        // }
    }
});
