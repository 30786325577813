
import { notify } from '@gm/components'
import { useLocalstorage } from '../localStorage/store'
import { useQueueDb } from '../indexedDb/queueDb'

const { persist } = useLocalstorage()

const state = reactive({
  queueSize: persist('queueSize', 0)
})

const queueSize = computed<number>(() => state.queueSize)

const updateQueueSize = async (): Promise<void> => {
  try {
    const { queueDb } = useQueueDb()
    const queue = await queueDb.getKeys()
    if (typeof queue !== 'undefined') {
      state.queueSize = queue.length
    }
  } catch (error) {
    notify(`Unable to update queue length. Error: ${error.toString()}`, {
      type: 'error',
      duration: 5000
    })
    throw new Error(error.toString())
  }
}

const resetQueueSize = (): void => {
  state.queueSize = 0
}

// Make sure that the queue size doesn not get "stuck"
updateQueueSize()

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQueueSizeStore = () => ({
  queueSize,
  resetQueueSize,
  updateQueueSize
})
