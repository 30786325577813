import { useDb } from './setup'
import type {Contract} from '~/models/contract';
import { isTempContract } from '~/helpers/contractHelper'
import cloneDeep from 'lodash/cloneDeep';

const { dbPromise } = useDb()

const insertContracts = async (contracts: Contract[]): Promise<boolean> => {
  try {
    // (await dbPromise).clear('contracts')
    const tx = (await dbPromise).transaction('contracts', 'readwrite')
    contracts.forEach((contract) => {
      if (contract.ToBeRemoved) {
        tx.store.delete(contract.Id)
      } else {
        tx.store.put(contract)
      }
    })
    await tx.done
    return true
  } catch (error) {
    console.error('insert Contract error', error)
    return false
  }
}

const removeDeletedContracts = async (existingContracts: Contract[]) => {
  try {
    const localKeys = await (await dbPromise).getAllKeys('contracts')
    const remoteKeys = existingContracts.map((contract) => contract.Id)
    const toBeRemoved = localKeys.filter((key) => {
      return !isTempContract(key) && !remoteKeys.includes(key)
    })
    const tx = (await dbPromise).transaction('contracts', 'readwrite')
    toBeRemoved.forEach((key) => {
      tx.store.delete(key)
    })
    await tx.done

  } catch (error) {
    console.error('removeDeletedContracts', error)
  }
}

const contractsDb = {
  async getById (id: string): Promise<Contract> {
    try {
      return (await dbPromise).get('contracts', id)
    } catch (error) {
      console.error('contractsDb: getById', error)
    }
  },
  async getAll (): Promise<Contract[]> {
    try {
      return (await dbPromise).getAll('contracts')
    } catch (error) {
      console.error('contractsDb: getAll', error)
    }
  },
  async getByType (type: number): Promise<Contract[]> {
    try {
      return (await dbPromise).getAllFromIndex('contracts', 'by-type', type)
    } catch (error) {
      console.error('contractsDb: getByType', error)
    }
  },
  async getByProperty (propertyId: string): Promise<Contract[]> {
    try {
      return (await dbPromise).getAllFromIndex('contracts', 'by-property', propertyId)
    } catch (error) {
      console.error('contractsDb: getByProperty', error)
    }
  },
  async setById (contract: Contract): Promise<string> {
    try {
      return (await dbPromise).put('contracts', cloneDeep(contract))
    } catch (error) {
      console.error('contractsDb: setById', error)
    }
  },
  async delete (id: Contract['Id']): Promise<void> {
    try {
      return (await dbPromise).delete('contracts', id)
    } catch (error) {
      console.error('contractsDb: delete', error)
    }
  },
  async clear (): Promise<void> {
    try {
      return (await dbPromise).clear('contracts')
    } catch (error) {
      console.error('contractsDb: clear', error)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useContractsDb = () => ({
  contractsDb,
  insertContracts,
  removeDeletedContracts,
})
