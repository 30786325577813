
import { useCacheDb } from '~/use/indexedDb/cacheDb'
import type {Ref} from 'vue';
import {cloneDeep} from 'lodash';

const { cacheDb } = useCacheDb()

const getStorageKey = (key: string): string => {
  return `sbl.${key}`
}

function persistLocalStorage<T>(key: string, defaultValue: T): Ref<T> {
  let variable: Ref
  const localStorageKey = getStorageKey(key)

  try {
    const init = localStorage.getItem(localStorageKey)
    variable = ref(init ? JSON.parse(init) : defaultValue)
  } catch {
    variable = ref(defaultValue)
  }

  watch(
      () => variable.value,
      (to) => {
        localStorage.setItem(localStorageKey, JSON.stringify(to))
      },
      {
        deep: true
      }
  )

  return variable
}

function persistIndexedDB<T>(key: string, defaultValue: T): Ref<T> {
  const variable = ref<T>(defaultValue) as Ref<T>

  async function setup() {
    const initialValue = await cacheDb.get(getStorageKey(key)) as T
    if (initialValue) {
      variable.value = initialValue
    }

    watch(() => variable.value, async (to) => {
      await cacheDb.put(getStorageKey(key), cloneDeep(to))
    }, {
      deep: true
    })
  }

  setup()

  return variable
}

const persist = <T>(key: string, defaultValue: T, storage: 'localStorage' | 'idb' = 'localStorage'): Ref<T> => {
  if (storage === 'localStorage') {
    return persistLocalStorage(key, defaultValue)
  } else if (storage === 'idb') {
    return persistIndexedDB(key, defaultValue)
  } else {
    throw new Error(`Invalid storage type: ${storage}`)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useLocalstorage = () => ({
  persist
})
