import { PublicClientApplication } from '@azure/msal-browser'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { featureEnabled } from '@/helpers/features'


export default defineNuxtPlugin((nuxtApp) => {
    const appInsightsEnabled = featureEnabled(nuxtApp.$config.public.FEATURE_APPLICATION_INSIGHTS)
    const appInsights = new ApplicationInsights({
        config: {
            connectionString: nuxtApp.$config.public.APPLICATION_INSIGHTS_CONNECTION_STRING,
            loggingLevelConsole: 2,
            loggingLevelTelemetry: 2,
            correlationHeaderDomains: ['*.glommen-mjosen.no'],
            enableCorsCorrelation: true,
            disableAjaxTracking: false,
            disableCorrelationHeaders: false,
            disableFetchTracking: false,
        }
    })

    appInsights.addTelemetryInitializer(envelope => {
        const $msal = nuxtApp.$msal as PublicClientApplication

        try {
            if (envelope?.data) {
                const userId = localStorage.getItem('sbl.dataUser')
                if (userId) {
                    envelope.data.userId = removeQuotes(userId)
                }
                if ($msal) {
                    $msal.initialize()
                    const activeAccount = $msal.getActiveAccount()

                    if (activeAccount) {
                        envelope.data.mail = activeAccount.username
                    }
                }
            }
        }
        catch {}

        return !!appInsightsEnabled

        function removeQuotes(userId: string): any {
            return userId.substring(1, userId.length - 1)
        }
    })

    appInsights.loadAppInsights();

    return {
        provide: {
            appInsights
        }
    }
})