/* tslint:disable */
/* eslint-disable */
/**
 * Mjøsen Skog skogbruksleder API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ActionModelsLibrarySBLFOCApproveProductionOrdersApproveProductionOrdersOutput } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoActionModelsLibraryGetRightToSignListOutput } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoContractProjectDto } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoContractWoodForOwnUseDto } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoFireWoodOrderDto } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoFirewoodOrderLineDto } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoProductionOrderDto } from '../model';
// @ts-ignore
import { GmModelLibraryModelsContractModel } from '../model';
// @ts-ignore
import { GmModelLibraryModelsContractsContractSimpleModel } from '../model';
// @ts-ignore
import { GmModelLibraryModelsEntrepreneurModel } from '../model';
/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Approve productionorders for contract
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractApproveProductionOrders: async (forestOperationContractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractApproveProductionOrders', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/approve-production-orders`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change entrepreneur on contract
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {GmModelLibraryModelsEntrepreneurModel} [gmModelLibraryModelsEntrepreneurModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractChangeEntrepreneur: async (forestOperationContractId: string, productionOrderId: string, gmModelLibraryModelsEntrepreneurModel?: GmModelLibraryModelsEntrepreneurModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractChangeEntrepreneur', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'productionOrderId' is not null or undefined
            assertParamExists('contractChangeEntrepreneur', 'productionOrderId', productionOrderId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/po/{productionOrderId}/changeEntrepreneur`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"productionOrderId"}}`, encodeURIComponent(String(productionOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmModelLibraryModelsEntrepreneurModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change entrepreneur on contract
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {string} newEntrepreneurTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractChangeEntrepreneurTeam: async (forestOperationContractId: string, productionOrderId: string, newEntrepreneurTeamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractChangeEntrepreneurTeam', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'productionOrderId' is not null or undefined
            assertParamExists('contractChangeEntrepreneurTeam', 'productionOrderId', productionOrderId)
            // verify required parameter 'newEntrepreneurTeamId' is not null or undefined
            assertParamExists('contractChangeEntrepreneurTeam', 'newEntrepreneurTeamId', newEntrepreneurTeamId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/po/{productionOrderId}/changeEntrepreneurTeam/{newEntrepreneurTeamId}`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"productionOrderId"}}`, encodeURIComponent(String(productionOrderId)))
                .replace(`{${"newEntrepreneurTeamId"}}`, encodeURIComponent(String(newEntrepreneurTeamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change production order type - toggle type between 1 (Hogst) and 2 (Framkjøring)
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractChangeProductionOrderType: async (forestOperationContractId: string, productionOrderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractChangeProductionOrderType', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'productionOrderId' is not null or undefined
            assertParamExists('contractChangeProductionOrderType', 'productionOrderId', productionOrderId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/po/{productionOrderId}/toggleType`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"productionOrderId"}}`, encodeURIComponent(String(productionOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if contract exist based on temp Id
         * @param {string} contractTempId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractContractWithSlbAppIdExist: async (contractTempId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractTempId' is not null or undefined
            assertParamExists('contractContractWithSlbAppIdExist', 'contractTempId', contractTempId)
            const localVarPath = `/v1/contracts/SLBAppIdExist/{contractTempId}`
                .replace(`{${"contractTempId"}}`, encodeURIComponent(String(contractTempId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new contract
         * @param {GmModelLibraryModelsContractModel} [gmModelLibraryModelsContractModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCreateContract: async (gmModelLibraryModelsContractModel?: GmModelLibraryModelsContractModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmModelLibraryModelsContractModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new firewood order
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoFireWoodOrderDto} [gmDtoLibraryDtoFireWoodOrderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCreateFireWoodOrder: async (forestOperationContractId: string, gmDtoLibraryDtoFireWoodOrderDto?: GmDtoLibraryDtoFireWoodOrderDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractCreateFireWoodOrder', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/firewood`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmDtoLibraryDtoFireWoodOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new firewood order line
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {GmDtoLibraryDtoFirewoodOrderLineDto} [gmDtoLibraryDtoFirewoodOrderLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCreateFireWoodOrderLine: async (forestOperationContractId: string, fireWoodOrderId: string, gmDtoLibraryDtoFirewoodOrderLineDto?: GmDtoLibraryDtoFirewoodOrderLineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractCreateFireWoodOrderLine', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'fireWoodOrderId' is not null or undefined
            assertParamExists('contractCreateFireWoodOrderLine', 'fireWoodOrderId', fireWoodOrderId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/firewood/{fireWoodOrderId}/orderLine`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"fireWoodOrderId"}}`, encodeURIComponent(String(fireWoodOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmDtoLibraryDtoFirewoodOrderLineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new production order based on existing po or toggle type  - automatic change in type will be done on existing po
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {number} type 
         * @param {GmModelLibraryModelsEntrepreneurModel} [gmModelLibraryModelsEntrepreneurModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCreateProductionOrder: async (forestOperationContractId: string, productionOrderId: string, type: number, gmModelLibraryModelsEntrepreneurModel?: GmModelLibraryModelsEntrepreneurModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractCreateProductionOrder', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'productionOrderId' is not null or undefined
            assertParamExists('contractCreateProductionOrder', 'productionOrderId', productionOrderId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('contractCreateProductionOrder', 'type', type)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/po/{productionOrderId}/newType/{type}`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"productionOrderId"}}`, encodeURIComponent(String(productionOrderId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmModelLibraryModelsEntrepreneurModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete entrepreneur from contract
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractDeleteEntrepreneur: async (forestOperationContractId: string, productionOrderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractDeleteEntrepreneur', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'productionOrderId' is not null or undefined
            assertParamExists('contractDeleteEntrepreneur', 'productionOrderId', productionOrderId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/po/{productionOrderId}`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"productionOrderId"}}`, encodeURIComponent(String(productionOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete firewood order
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractDeleteFireWoodOrder: async (forestOperationContractId: string, fireWoodOrderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractDeleteFireWoodOrder', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'fireWoodOrderId' is not null or undefined
            assertParamExists('contractDeleteFireWoodOrder', 'fireWoodOrderId', fireWoodOrderId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/firewood/{fireWoodOrderId}`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"fireWoodOrderId"}}`, encodeURIComponent(String(fireWoodOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete firewood order line
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {string} fireWoodOrderLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractDeleteFireWoodOrderLine: async (forestOperationContractId: string, fireWoodOrderId: string, fireWoodOrderLineId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractDeleteFireWoodOrderLine', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'fireWoodOrderId' is not null or undefined
            assertParamExists('contractDeleteFireWoodOrderLine', 'fireWoodOrderId', fireWoodOrderId)
            // verify required parameter 'fireWoodOrderLineId' is not null or undefined
            assertParamExists('contractDeleteFireWoodOrderLine', 'fireWoodOrderLineId', fireWoodOrderLineId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/fireWood/{fireWoodOrderId}/orderLine/{fireWoodOrderLineId}`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"fireWoodOrderId"}}`, encodeURIComponent(String(fireWoodOrderId)))
                .replace(`{${"fireWoodOrderLineId"}}`, encodeURIComponent(String(fireWoodOrderLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set contract to VH accepted
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractForestOperatorContractActivation: async (forestOperationContractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractForestOperatorContractActivation', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/contractActivation`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContract: async (contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetContract', 'contractId', contractId)
            const localVarPath = `/v1/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all contract that user has access too  - New Design 2023Q2
         * @param {string} [userId] 
         * @param {string} [lastUpdated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractList: async (userId?: string, lastUpdated?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contracts/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (lastUpdated !== undefined) {
                localVarQueryParameter['lastUpdated'] = (lastUpdated as any instanceof Date) ?
                    (lastUpdated as any).toISOString() :
                    lastUpdated;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract pdf by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractPdf: async (contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetContractPdf', 'contractId', contractId)
            const localVarPath = `/v1/contracts/{contractId}/pdf`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project info
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractProject: async (contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetContractProject', 'contractId', contractId)
            const localVarPath = `/v1/contracts/{contractId}/project`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contracts by current user.
         * @param {string} [lastUpdated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContracts: async (lastUpdated?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lastUpdated !== undefined) {
                localVarQueryParameter['lastUpdated'] = (lastUpdated as any instanceof Date) ?
                    (lastUpdated as any).toISOString() :
                    lastUpdated;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get status for entrepreneur  - Has production started?  - Has delivery to road started?
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {string} [entrepreneurId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetEntrepreneurStartedStatus: async (forestOperationContractId: string, productionOrderId: string, entrepreneurId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractGetEntrepreneurStartedStatus', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'productionOrderId' is not null or undefined
            assertParamExists('contractGetEntrepreneurStartedStatus', 'productionOrderId', productionOrderId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/po/{productionOrderId}/entrepreneur/status`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"productionOrderId"}}`, encodeURIComponent(String(productionOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entrepreneurId !== undefined) {
                localVarQueryParameter['entrepreneurId'] = entrepreneurId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get firewood order
         * @param {string} forestOperationContractId 
         * @param {string} firewoodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetFireWoodOrder: async (forestOperationContractId: string, firewoodId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractGetFireWoodOrder', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'firewoodId' is not null or undefined
            assertParamExists('contractGetFireWoodOrder', 'firewoodId', firewoodId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/firewood/{firewoodId}`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"firewoodId"}}`, encodeURIComponent(String(firewoodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get firewood order line
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {string} fireWoodOrderLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetFireWoodOrderLine: async (forestOperationContractId: string, fireWoodOrderId: string, fireWoodOrderLineId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractGetFireWoodOrderLine', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'fireWoodOrderId' is not null or undefined
            assertParamExists('contractGetFireWoodOrderLine', 'fireWoodOrderId', fireWoodOrderId)
            // verify required parameter 'fireWoodOrderLineId' is not null or undefined
            assertParamExists('contractGetFireWoodOrderLine', 'fireWoodOrderLineId', fireWoodOrderLineId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/firewood/{fireWoodOrderId}/orderLine/{fireWoodOrderLineId}`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"fireWoodOrderId"}}`, encodeURIComponent(String(fireWoodOrderId)))
                .replace(`{${"fireWoodOrderLineId"}}`, encodeURIComponent(String(fireWoodOrderLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get fresh contract pdf by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetFreshContractPdf: async (contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractGetFreshContractPdf', 'contractId', contractId)
            const localVarPath = `/v1/contracts/{contractId}/pdfdebug`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calls portal endpoint.  Returns the list over the people who have right to sign for a property - JSON
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetPropertyRightToSing: async (propertyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('contractGetPropertyRightToSing', 'propertyId', propertyId)
            const localVarPath = `/v1/contracts/get-property-right-to-sign/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information about wood for own use
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetWoodForOwnUse: async (forestOperationContractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractGetWoodForOwnUse', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/woodForOwnUse`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List firewood order lines
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractListFireWoodOrderLines: async (forestOperationContractId: string, fireWoodOrderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractListFireWoodOrderLines', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'fireWoodOrderId' is not null or undefined
            assertParamExists('contractListFireWoodOrderLines', 'fireWoodOrderId', fireWoodOrderId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/firewood/{fireWoodOrderId}/orderLines`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"fireWoodOrderId"}}`, encodeURIComponent(String(fireWoodOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List firewood orders
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractListFireWoodOrders: async (forestOperationContractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractListFireWoodOrders', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/firewood/orderList`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List production orders on contract
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractListProductionOrders: async (forestOperationContractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractListProductionOrders', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/productionOrders`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List wood for own use orders
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractListWoodForOwnUseOrders: async (forestOperationContractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractListWoodForOwnUseOrders', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/woodForOwnUse/orderList`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Regenerate contract pdf by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRegenerateContractPdf: async (contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractRegenerateContractPdf', 'contractId', contractId)
            const localVarPath = `/v1/contracts/public/{contractId}/pdf`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update contract with a sorting number - available in contract listing
         * @param {string} contractId 
         * @param {number} sorting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSetContractSorting: async (contractId: string, sorting: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractSetContractSorting', 'contractId', contractId)
            // verify required parameter 'sorting' is not null or undefined
            assertParamExists('contractSetContractSorting', 'sorting', sorting)
            const localVarPath = `/v1/contracts/{contractId}/sorting/{sorting}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"sorting"}}`, encodeURIComponent(String(sorting)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update contract
         * @param {string} contractId 
         * @param {GmModelLibraryModelsContractModel} [gmModelLibraryModelsContractModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUpdateContract: async (contractId: string, gmModelLibraryModelsContractModel?: GmModelLibraryModelsContractModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractUpdateContract', 'contractId', contractId)
            const localVarPath = `/v1/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmModelLibraryModelsContractModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change contract period
         * @param {string} contractDetailId 
         * @param {string} newPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUpdateContractDetailPeriodAction: async (contractDetailId: string, newPeriod: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractDetailId' is not null or undefined
            assertParamExists('contractUpdateContractDetailPeriodAction', 'contractDetailId', contractDetailId)
            // verify required parameter 'newPeriod' is not null or undefined
            assertParamExists('contractUpdateContractDetailPeriodAction', 'newPeriod', newPeriod)
            const localVarPath = `/v1/contracts/change-contract-detail-period/{contractDetailId}/{newPeriod}`
                .replace(`{${"contractDetailId"}}`, encodeURIComponent(String(contractDetailId)))
                .replace(`{${"newPeriod"}}`, encodeURIComponent(String(newPeriod)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change contract period
         * @param {string} contractId 
         * @param {string} newPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUpdateContractPeriodAction: async (contractId: string, newPeriod: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractUpdateContractPeriodAction', 'contractId', contractId)
            // verify required parameter 'newPeriod' is not null or undefined
            assertParamExists('contractUpdateContractPeriodAction', 'newPeriod', newPeriod)
            const localVarPath = `/v1/contracts/{contractId}/change-period/{newPeriod}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"newPeriod"}}`, encodeURIComponent(String(newPeriod)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update firewood order
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoFireWoodOrderDto} [gmDtoLibraryDtoFireWoodOrderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUpdateFireWoodOrder: async (forestOperationContractId: string, gmDtoLibraryDtoFireWoodOrderDto?: GmDtoLibraryDtoFireWoodOrderDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractUpdateFireWoodOrder', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/firewood`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmDtoLibraryDtoFireWoodOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update firewood order line
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoFirewoodOrderLineDto} [gmDtoLibraryDtoFirewoodOrderLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUpdateFireWoodOrderLine: async (forestOperationContractId: string, gmDtoLibraryDtoFirewoodOrderLineDto?: GmDtoLibraryDtoFirewoodOrderLineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractUpdateFireWoodOrderLine', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/firewood/orderLine`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmDtoLibraryDtoFirewoodOrderLineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update wood for own use order
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoContractWoodForOwnUseDto} [gmDtoLibraryDtoContractWoodForOwnUseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUpdateWoodForOwnUse: async (forestOperationContractId: string, gmDtoLibraryDtoContractWoodForOwnUseDto?: GmDtoLibraryDtoContractWoodForOwnUseDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('contractUpdateWoodForOwnUse', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/contracts/{forestOperationContractId}/woodForOwnUse/update`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmDtoLibraryDtoContractWoodForOwnUseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Approve productionorders for contract
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractApproveProductionOrders(forestOperationContractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionModelsLibrarySBLFOCApproveProductionOrdersApproveProductionOrdersOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractApproveProductionOrders(forestOperationContractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractApproveProductionOrders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Change entrepreneur on contract
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {GmModelLibraryModelsEntrepreneurModel} [gmModelLibraryModelsEntrepreneurModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractChangeEntrepreneur(forestOperationContractId: string, productionOrderId: string, gmModelLibraryModelsEntrepreneurModel?: GmModelLibraryModelsEntrepreneurModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoProductionOrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractChangeEntrepreneur(forestOperationContractId, productionOrderId, gmModelLibraryModelsEntrepreneurModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractChangeEntrepreneur']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Change entrepreneur on contract
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {string} newEntrepreneurTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractChangeEntrepreneurTeam(forestOperationContractId: string, productionOrderId: string, newEntrepreneurTeamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoProductionOrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractChangeEntrepreneurTeam(forestOperationContractId, productionOrderId, newEntrepreneurTeamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractChangeEntrepreneurTeam']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Change production order type - toggle type between 1 (Hogst) and 2 (Framkjøring)
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractChangeProductionOrderType(forestOperationContractId: string, productionOrderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoProductionOrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractChangeProductionOrderType(forestOperationContractId, productionOrderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractChangeProductionOrderType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Check if contract exist based on temp Id
         * @param {string} contractTempId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractContractWithSlbAppIdExist(contractTempId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsContractModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractContractWithSlbAppIdExist(contractTempId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractContractWithSlbAppIdExist']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create new contract
         * @param {GmModelLibraryModelsContractModel} [gmModelLibraryModelsContractModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractCreateContract(gmModelLibraryModelsContractModel?: GmModelLibraryModelsContractModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsContractModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractCreateContract(gmModelLibraryModelsContractModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractCreateContract']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create new firewood order
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoFireWoodOrderDto} [gmDtoLibraryDtoFireWoodOrderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractCreateFireWoodOrder(forestOperationContractId: string, gmDtoLibraryDtoFireWoodOrderDto?: GmDtoLibraryDtoFireWoodOrderDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoFireWoodOrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractCreateFireWoodOrder(forestOperationContractId, gmDtoLibraryDtoFireWoodOrderDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractCreateFireWoodOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create new firewood order line
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {GmDtoLibraryDtoFirewoodOrderLineDto} [gmDtoLibraryDtoFirewoodOrderLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractCreateFireWoodOrderLine(forestOperationContractId: string, fireWoodOrderId: string, gmDtoLibraryDtoFirewoodOrderLineDto?: GmDtoLibraryDtoFirewoodOrderLineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoFirewoodOrderLineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractCreateFireWoodOrderLine(forestOperationContractId, fireWoodOrderId, gmDtoLibraryDtoFirewoodOrderLineDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractCreateFireWoodOrderLine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create new production order based on existing po or toggle type  - automatic change in type will be done on existing po
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {number} type 
         * @param {GmModelLibraryModelsEntrepreneurModel} [gmModelLibraryModelsEntrepreneurModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractCreateProductionOrder(forestOperationContractId: string, productionOrderId: string, type: number, gmModelLibraryModelsEntrepreneurModel?: GmModelLibraryModelsEntrepreneurModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoProductionOrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractCreateProductionOrder(forestOperationContractId, productionOrderId, type, gmModelLibraryModelsEntrepreneurModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractCreateProductionOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete entrepreneur from contract
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractDeleteEntrepreneur(forestOperationContractId: string, productionOrderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractDeleteEntrepreneur(forestOperationContractId, productionOrderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractDeleteEntrepreneur']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete firewood order
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractDeleteFireWoodOrder(forestOperationContractId: string, fireWoodOrderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractDeleteFireWoodOrder(forestOperationContractId, fireWoodOrderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractDeleteFireWoodOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete firewood order line
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {string} fireWoodOrderLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractDeleteFireWoodOrderLine(forestOperationContractId: string, fireWoodOrderId: string, fireWoodOrderLineId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractDeleteFireWoodOrderLine(forestOperationContractId, fireWoodOrderId, fireWoodOrderLineId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractDeleteFireWoodOrderLine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Set contract to VH accepted
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractForestOperatorContractActivation(forestOperationContractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractForestOperatorContractActivation(forestOperationContractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractForestOperatorContractActivation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get contract by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetContract(contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsContractModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetContract(contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetContract']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary List all contract that user has access too  - New Design 2023Q2
         * @param {string} [userId] 
         * @param {string} [lastUpdated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetContractList(userId?: string, lastUpdated?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmModelLibraryModelsContractsContractSimpleModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetContractList(userId, lastUpdated, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetContractList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get contract pdf by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetContractPdf(contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetContractPdf(contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetContractPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get project info
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetContractProject(contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoContractProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetContractProject(contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetContractProject']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get contracts by current user.
         * @param {string} [lastUpdated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetContracts(lastUpdated?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmModelLibraryModelsContractsContractSimpleModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetContracts(lastUpdated, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetContracts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get status for entrepreneur  - Has production started?  - Has delivery to road started?
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {string} [entrepreneurId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetEntrepreneurStartedStatus(forestOperationContractId: string, productionOrderId: string, entrepreneurId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetEntrepreneurStartedStatus(forestOperationContractId, productionOrderId, entrepreneurId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetEntrepreneurStartedStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get firewood order
         * @param {string} forestOperationContractId 
         * @param {string} firewoodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetFireWoodOrder(forestOperationContractId: string, firewoodId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoFireWoodOrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetFireWoodOrder(forestOperationContractId, firewoodId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetFireWoodOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get firewood order line
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {string} fireWoodOrderLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetFireWoodOrderLine(forestOperationContractId: string, fireWoodOrderId: string, fireWoodOrderLineId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoFirewoodOrderLineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetFireWoodOrderLine(forestOperationContractId, fireWoodOrderId, fireWoodOrderLineId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetFireWoodOrderLine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get fresh contract pdf by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetFreshContractPdf(contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetFreshContractPdf(contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetFreshContractPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Calls portal endpoint.  Returns the list over the people who have right to sign for a property - JSON
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetPropertyRightToSing(propertyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoActionModelsLibraryGetRightToSignListOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetPropertyRightToSing(propertyId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetPropertyRightToSing']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get information about wood for own use
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetWoodForOwnUse(forestOperationContractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoContractWoodForOwnUseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetWoodForOwnUse(forestOperationContractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractGetWoodForOwnUse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary List firewood order lines
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractListFireWoodOrderLines(forestOperationContractId: string, fireWoodOrderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoFirewoodOrderLineDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractListFireWoodOrderLines(forestOperationContractId, fireWoodOrderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractListFireWoodOrderLines']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary List firewood orders
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractListFireWoodOrders(forestOperationContractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoFireWoodOrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractListFireWoodOrders(forestOperationContractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractListFireWoodOrders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary List production orders on contract
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractListProductionOrders(forestOperationContractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoProductionOrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractListProductionOrders(forestOperationContractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractListProductionOrders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary List wood for own use orders
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractListWoodForOwnUseOrders(forestOperationContractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoContractWoodForOwnUseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractListWoodForOwnUseOrders(forestOperationContractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractListWoodForOwnUseOrders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Regenerate contract pdf by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractRegenerateContractPdf(contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractRegenerateContractPdf(contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractRegenerateContractPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update contract with a sorting number - available in contract listing
         * @param {string} contractId 
         * @param {number} sorting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractSetContractSorting(contractId: string, sorting: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractSetContractSorting(contractId, sorting, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractSetContractSorting']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update contract
         * @param {string} contractId 
         * @param {GmModelLibraryModelsContractModel} [gmModelLibraryModelsContractModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractUpdateContract(contractId: string, gmModelLibraryModelsContractModel?: GmModelLibraryModelsContractModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsContractModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractUpdateContract(contractId, gmModelLibraryModelsContractModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractUpdateContract']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Change contract period
         * @param {string} contractDetailId 
         * @param {string} newPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractUpdateContractDetailPeriodAction(contractDetailId: string, newPeriod: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractUpdateContractDetailPeriodAction(contractDetailId, newPeriod, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractUpdateContractDetailPeriodAction']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Change contract period
         * @param {string} contractId 
         * @param {string} newPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractUpdateContractPeriodAction(contractId: string, newPeriod: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractUpdateContractPeriodAction(contractId, newPeriod, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractUpdateContractPeriodAction']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update firewood order
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoFireWoodOrderDto} [gmDtoLibraryDtoFireWoodOrderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractUpdateFireWoodOrder(forestOperationContractId: string, gmDtoLibraryDtoFireWoodOrderDto?: GmDtoLibraryDtoFireWoodOrderDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoFireWoodOrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractUpdateFireWoodOrder(forestOperationContractId, gmDtoLibraryDtoFireWoodOrderDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractUpdateFireWoodOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update firewood order line
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoFirewoodOrderLineDto} [gmDtoLibraryDtoFirewoodOrderLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractUpdateFireWoodOrderLine(forestOperationContractId: string, gmDtoLibraryDtoFirewoodOrderLineDto?: GmDtoLibraryDtoFirewoodOrderLineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoFirewoodOrderLineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractUpdateFireWoodOrderLine(forestOperationContractId, gmDtoLibraryDtoFirewoodOrderLineDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractUpdateFireWoodOrderLine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update wood for own use order
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoContractWoodForOwnUseDto} [gmDtoLibraryDtoContractWoodForOwnUseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractUpdateWoodForOwnUse(forestOperationContractId: string, gmDtoLibraryDtoContractWoodForOwnUseDto?: GmDtoLibraryDtoContractWoodForOwnUseDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoContractWoodForOwnUseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractUpdateWoodForOwnUse(forestOperationContractId, gmDtoLibraryDtoContractWoodForOwnUseDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ContractApi.contractUpdateWoodForOwnUse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractApiFp(configuration)
    return {
        /**
         * 
         * @summary Approve productionorders for contract
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractApproveProductionOrders(forestOperationContractId: string, options?: any): AxiosPromise<ActionModelsLibrarySBLFOCApproveProductionOrdersApproveProductionOrdersOutput> {
            return localVarFp.contractApproveProductionOrders(forestOperationContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change entrepreneur on contract
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {GmModelLibraryModelsEntrepreneurModel} [gmModelLibraryModelsEntrepreneurModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractChangeEntrepreneur(forestOperationContractId: string, productionOrderId: string, gmModelLibraryModelsEntrepreneurModel?: GmModelLibraryModelsEntrepreneurModel, options?: any): AxiosPromise<GmDtoLibraryDtoProductionOrderDto> {
            return localVarFp.contractChangeEntrepreneur(forestOperationContractId, productionOrderId, gmModelLibraryModelsEntrepreneurModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change entrepreneur on contract
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {string} newEntrepreneurTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractChangeEntrepreneurTeam(forestOperationContractId: string, productionOrderId: string, newEntrepreneurTeamId: string, options?: any): AxiosPromise<GmDtoLibraryDtoProductionOrderDto> {
            return localVarFp.contractChangeEntrepreneurTeam(forestOperationContractId, productionOrderId, newEntrepreneurTeamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change production order type - toggle type between 1 (Hogst) and 2 (Framkjøring)
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractChangeProductionOrderType(forestOperationContractId: string, productionOrderId: string, options?: any): AxiosPromise<Array<GmDtoLibraryDtoProductionOrderDto>> {
            return localVarFp.contractChangeProductionOrderType(forestOperationContractId, productionOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if contract exist based on temp Id
         * @param {string} contractTempId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractContractWithSlbAppIdExist(contractTempId: string, options?: any): AxiosPromise<GmModelLibraryModelsContractModel> {
            return localVarFp.contractContractWithSlbAppIdExist(contractTempId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new contract
         * @param {GmModelLibraryModelsContractModel} [gmModelLibraryModelsContractModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCreateContract(gmModelLibraryModelsContractModel?: GmModelLibraryModelsContractModel, options?: any): AxiosPromise<GmModelLibraryModelsContractModel> {
            return localVarFp.contractCreateContract(gmModelLibraryModelsContractModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new firewood order
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoFireWoodOrderDto} [gmDtoLibraryDtoFireWoodOrderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCreateFireWoodOrder(forestOperationContractId: string, gmDtoLibraryDtoFireWoodOrderDto?: GmDtoLibraryDtoFireWoodOrderDto, options?: any): AxiosPromise<GmDtoLibraryDtoFireWoodOrderDto> {
            return localVarFp.contractCreateFireWoodOrder(forestOperationContractId, gmDtoLibraryDtoFireWoodOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new firewood order line
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {GmDtoLibraryDtoFirewoodOrderLineDto} [gmDtoLibraryDtoFirewoodOrderLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCreateFireWoodOrderLine(forestOperationContractId: string, fireWoodOrderId: string, gmDtoLibraryDtoFirewoodOrderLineDto?: GmDtoLibraryDtoFirewoodOrderLineDto, options?: any): AxiosPromise<GmDtoLibraryDtoFirewoodOrderLineDto> {
            return localVarFp.contractCreateFireWoodOrderLine(forestOperationContractId, fireWoodOrderId, gmDtoLibraryDtoFirewoodOrderLineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new production order based on existing po or toggle type  - automatic change in type will be done on existing po
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {number} type 
         * @param {GmModelLibraryModelsEntrepreneurModel} [gmModelLibraryModelsEntrepreneurModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCreateProductionOrder(forestOperationContractId: string, productionOrderId: string, type: number, gmModelLibraryModelsEntrepreneurModel?: GmModelLibraryModelsEntrepreneurModel, options?: any): AxiosPromise<Array<GmDtoLibraryDtoProductionOrderDto>> {
            return localVarFp.contractCreateProductionOrder(forestOperationContractId, productionOrderId, type, gmModelLibraryModelsEntrepreneurModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete entrepreneur from contract
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractDeleteEntrepreneur(forestOperationContractId: string, productionOrderId: string, options?: any): AxiosPromise<string> {
            return localVarFp.contractDeleteEntrepreneur(forestOperationContractId, productionOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete firewood order
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractDeleteFireWoodOrder(forestOperationContractId: string, fireWoodOrderId: string, options?: any): AxiosPromise<string> {
            return localVarFp.contractDeleteFireWoodOrder(forestOperationContractId, fireWoodOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete firewood order line
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {string} fireWoodOrderLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractDeleteFireWoodOrderLine(forestOperationContractId: string, fireWoodOrderId: string, fireWoodOrderLineId: string, options?: any): AxiosPromise<string> {
            return localVarFp.contractDeleteFireWoodOrderLine(forestOperationContractId, fireWoodOrderId, fireWoodOrderLineId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set contract to VH accepted
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractForestOperatorContractActivation(forestOperationContractId: string, options?: any): AxiosPromise<string> {
            return localVarFp.contractForestOperatorContractActivation(forestOperationContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContract(contractId: string, options?: any): AxiosPromise<GmModelLibraryModelsContractModel> {
            return localVarFp.contractGetContract(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all contract that user has access too  - New Design 2023Q2
         * @param {string} [userId] 
         * @param {string} [lastUpdated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractList(userId?: string, lastUpdated?: string, options?: any): AxiosPromise<Array<GmModelLibraryModelsContractsContractSimpleModel>> {
            return localVarFp.contractGetContractList(userId, lastUpdated, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract pdf by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractPdf(contractId: string, options?: any): AxiosPromise<void> {
            return localVarFp.contractGetContractPdf(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project info
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContractProject(contractId: string, options?: any): AxiosPromise<GmDtoLibraryDtoContractProjectDto> {
            return localVarFp.contractGetContractProject(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contracts by current user.
         * @param {string} [lastUpdated] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetContracts(lastUpdated?: string, options?: any): AxiosPromise<Array<GmModelLibraryModelsContractsContractSimpleModel>> {
            return localVarFp.contractGetContracts(lastUpdated, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get status for entrepreneur  - Has production started?  - Has delivery to road started?
         * @param {string} forestOperationContractId 
         * @param {string} productionOrderId 
         * @param {string} [entrepreneurId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetEntrepreneurStartedStatus(forestOperationContractId: string, productionOrderId: string, entrepreneurId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.contractGetEntrepreneurStartedStatus(forestOperationContractId, productionOrderId, entrepreneurId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get firewood order
         * @param {string} forestOperationContractId 
         * @param {string} firewoodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetFireWoodOrder(forestOperationContractId: string, firewoodId: string, options?: any): AxiosPromise<GmDtoLibraryDtoFireWoodOrderDto> {
            return localVarFp.contractGetFireWoodOrder(forestOperationContractId, firewoodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get firewood order line
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {string} fireWoodOrderLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetFireWoodOrderLine(forestOperationContractId: string, fireWoodOrderId: string, fireWoodOrderLineId: string, options?: any): AxiosPromise<GmDtoLibraryDtoFirewoodOrderLineDto> {
            return localVarFp.contractGetFireWoodOrderLine(forestOperationContractId, fireWoodOrderId, fireWoodOrderLineId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get fresh contract pdf by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetFreshContractPdf(contractId: string, options?: any): AxiosPromise<void> {
            return localVarFp.contractGetFreshContractPdf(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calls portal endpoint.  Returns the list over the people who have right to sign for a property - JSON
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetPropertyRightToSing(propertyId: string, options?: any): AxiosPromise<GmDtoLibraryDtoActionModelsLibraryGetRightToSignListOutput> {
            return localVarFp.contractGetPropertyRightToSing(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information about wood for own use
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetWoodForOwnUse(forestOperationContractId: string, options?: any): AxiosPromise<GmDtoLibraryDtoContractWoodForOwnUseDto> {
            return localVarFp.contractGetWoodForOwnUse(forestOperationContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List firewood order lines
         * @param {string} forestOperationContractId 
         * @param {string} fireWoodOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractListFireWoodOrderLines(forestOperationContractId: string, fireWoodOrderId: string, options?: any): AxiosPromise<Array<GmDtoLibraryDtoFirewoodOrderLineDto>> {
            return localVarFp.contractListFireWoodOrderLines(forestOperationContractId, fireWoodOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List firewood orders
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractListFireWoodOrders(forestOperationContractId: string, options?: any): AxiosPromise<Array<GmDtoLibraryDtoFireWoodOrderDto>> {
            return localVarFp.contractListFireWoodOrders(forestOperationContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List production orders on contract
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractListProductionOrders(forestOperationContractId: string, options?: any): AxiosPromise<Array<GmDtoLibraryDtoProductionOrderDto>> {
            return localVarFp.contractListProductionOrders(forestOperationContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List wood for own use orders
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractListWoodForOwnUseOrders(forestOperationContractId: string, options?: any): AxiosPromise<Array<GmDtoLibraryDtoContractWoodForOwnUseDto>> {
            return localVarFp.contractListWoodForOwnUseOrders(forestOperationContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Regenerate contract pdf by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRegenerateContractPdf(contractId: string, options?: any): AxiosPromise<void> {
            return localVarFp.contractRegenerateContractPdf(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update contract with a sorting number - available in contract listing
         * @param {string} contractId 
         * @param {number} sorting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSetContractSorting(contractId: string, sorting: number, options?: any): AxiosPromise<void> {
            return localVarFp.contractSetContractSorting(contractId, sorting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update contract
         * @param {string} contractId 
         * @param {GmModelLibraryModelsContractModel} [gmModelLibraryModelsContractModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUpdateContract(contractId: string, gmModelLibraryModelsContractModel?: GmModelLibraryModelsContractModel, options?: any): AxiosPromise<GmModelLibraryModelsContractModel> {
            return localVarFp.contractUpdateContract(contractId, gmModelLibraryModelsContractModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change contract period
         * @param {string} contractDetailId 
         * @param {string} newPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUpdateContractDetailPeriodAction(contractDetailId: string, newPeriod: string, options?: any): AxiosPromise<string> {
            return localVarFp.contractUpdateContractDetailPeriodAction(contractDetailId, newPeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change contract period
         * @param {string} contractId 
         * @param {string} newPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUpdateContractPeriodAction(contractId: string, newPeriod: string, options?: any): AxiosPromise<string> {
            return localVarFp.contractUpdateContractPeriodAction(contractId, newPeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update firewood order
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoFireWoodOrderDto} [gmDtoLibraryDtoFireWoodOrderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUpdateFireWoodOrder(forestOperationContractId: string, gmDtoLibraryDtoFireWoodOrderDto?: GmDtoLibraryDtoFireWoodOrderDto, options?: any): AxiosPromise<GmDtoLibraryDtoFireWoodOrderDto> {
            return localVarFp.contractUpdateFireWoodOrder(forestOperationContractId, gmDtoLibraryDtoFireWoodOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update firewood order line
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoFirewoodOrderLineDto} [gmDtoLibraryDtoFirewoodOrderLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUpdateFireWoodOrderLine(forestOperationContractId: string, gmDtoLibraryDtoFirewoodOrderLineDto?: GmDtoLibraryDtoFirewoodOrderLineDto, options?: any): AxiosPromise<GmDtoLibraryDtoFirewoodOrderLineDto> {
            return localVarFp.contractUpdateFireWoodOrderLine(forestOperationContractId, gmDtoLibraryDtoFirewoodOrderLineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update wood for own use order
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoContractWoodForOwnUseDto} [gmDtoLibraryDtoContractWoodForOwnUseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractUpdateWoodForOwnUse(forestOperationContractId: string, gmDtoLibraryDtoContractWoodForOwnUseDto?: GmDtoLibraryDtoContractWoodForOwnUseDto, options?: any): AxiosPromise<GmDtoLibraryDtoContractWoodForOwnUseDto> {
            return localVarFp.contractUpdateWoodForOwnUse(forestOperationContractId, gmDtoLibraryDtoContractWoodForOwnUseDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * 
     * @summary Approve productionorders for contract
     * @param {string} forestOperationContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractApproveProductionOrders(forestOperationContractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractApproveProductionOrders(forestOperationContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change entrepreneur on contract
     * @param {string} forestOperationContractId 
     * @param {string} productionOrderId 
     * @param {GmModelLibraryModelsEntrepreneurModel} [gmModelLibraryModelsEntrepreneurModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractChangeEntrepreneur(forestOperationContractId: string, productionOrderId: string, gmModelLibraryModelsEntrepreneurModel?: GmModelLibraryModelsEntrepreneurModel, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractChangeEntrepreneur(forestOperationContractId, productionOrderId, gmModelLibraryModelsEntrepreneurModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change entrepreneur on contract
     * @param {string} forestOperationContractId 
     * @param {string} productionOrderId 
     * @param {string} newEntrepreneurTeamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractChangeEntrepreneurTeam(forestOperationContractId: string, productionOrderId: string, newEntrepreneurTeamId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractChangeEntrepreneurTeam(forestOperationContractId, productionOrderId, newEntrepreneurTeamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change production order type - toggle type between 1 (Hogst) and 2 (Framkjøring)
     * @param {string} forestOperationContractId 
     * @param {string} productionOrderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractChangeProductionOrderType(forestOperationContractId: string, productionOrderId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractChangeProductionOrderType(forestOperationContractId, productionOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if contract exist based on temp Id
     * @param {string} contractTempId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractContractWithSlbAppIdExist(contractTempId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractContractWithSlbAppIdExist(contractTempId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new contract
     * @param {GmModelLibraryModelsContractModel} [gmModelLibraryModelsContractModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractCreateContract(gmModelLibraryModelsContractModel?: GmModelLibraryModelsContractModel, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractCreateContract(gmModelLibraryModelsContractModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new firewood order
     * @param {string} forestOperationContractId 
     * @param {GmDtoLibraryDtoFireWoodOrderDto} [gmDtoLibraryDtoFireWoodOrderDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractCreateFireWoodOrder(forestOperationContractId: string, gmDtoLibraryDtoFireWoodOrderDto?: GmDtoLibraryDtoFireWoodOrderDto, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractCreateFireWoodOrder(forestOperationContractId, gmDtoLibraryDtoFireWoodOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new firewood order line
     * @param {string} forestOperationContractId 
     * @param {string} fireWoodOrderId 
     * @param {GmDtoLibraryDtoFirewoodOrderLineDto} [gmDtoLibraryDtoFirewoodOrderLineDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractCreateFireWoodOrderLine(forestOperationContractId: string, fireWoodOrderId: string, gmDtoLibraryDtoFirewoodOrderLineDto?: GmDtoLibraryDtoFirewoodOrderLineDto, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractCreateFireWoodOrderLine(forestOperationContractId, fireWoodOrderId, gmDtoLibraryDtoFirewoodOrderLineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new production order based on existing po or toggle type  - automatic change in type will be done on existing po
     * @param {string} forestOperationContractId 
     * @param {string} productionOrderId 
     * @param {number} type 
     * @param {GmModelLibraryModelsEntrepreneurModel} [gmModelLibraryModelsEntrepreneurModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractCreateProductionOrder(forestOperationContractId: string, productionOrderId: string, type: number, gmModelLibraryModelsEntrepreneurModel?: GmModelLibraryModelsEntrepreneurModel, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractCreateProductionOrder(forestOperationContractId, productionOrderId, type, gmModelLibraryModelsEntrepreneurModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete entrepreneur from contract
     * @param {string} forestOperationContractId 
     * @param {string} productionOrderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractDeleteEntrepreneur(forestOperationContractId: string, productionOrderId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractDeleteEntrepreneur(forestOperationContractId, productionOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete firewood order
     * @param {string} forestOperationContractId 
     * @param {string} fireWoodOrderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractDeleteFireWoodOrder(forestOperationContractId: string, fireWoodOrderId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractDeleteFireWoodOrder(forestOperationContractId, fireWoodOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete firewood order line
     * @param {string} forestOperationContractId 
     * @param {string} fireWoodOrderId 
     * @param {string} fireWoodOrderLineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractDeleteFireWoodOrderLine(forestOperationContractId: string, fireWoodOrderId: string, fireWoodOrderLineId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractDeleteFireWoodOrderLine(forestOperationContractId, fireWoodOrderId, fireWoodOrderLineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set contract to VH accepted
     * @param {string} forestOperationContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractForestOperatorContractActivation(forestOperationContractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractForestOperatorContractActivation(forestOperationContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract by id
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetContract(contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetContract(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all contract that user has access too  - New Design 2023Q2
     * @param {string} [userId] 
     * @param {string} [lastUpdated] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetContractList(userId?: string, lastUpdated?: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetContractList(userId, lastUpdated, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract pdf by id
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetContractPdf(contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetContractPdf(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project info
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetContractProject(contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetContractProject(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contracts by current user.
     * @param {string} [lastUpdated] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetContracts(lastUpdated?: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetContracts(lastUpdated, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get status for entrepreneur  - Has production started?  - Has delivery to road started?
     * @param {string} forestOperationContractId 
     * @param {string} productionOrderId 
     * @param {string} [entrepreneurId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetEntrepreneurStartedStatus(forestOperationContractId: string, productionOrderId: string, entrepreneurId?: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetEntrepreneurStartedStatus(forestOperationContractId, productionOrderId, entrepreneurId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get firewood order
     * @param {string} forestOperationContractId 
     * @param {string} firewoodId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetFireWoodOrder(forestOperationContractId: string, firewoodId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetFireWoodOrder(forestOperationContractId, firewoodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get firewood order line
     * @param {string} forestOperationContractId 
     * @param {string} fireWoodOrderId 
     * @param {string} fireWoodOrderLineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetFireWoodOrderLine(forestOperationContractId: string, fireWoodOrderId: string, fireWoodOrderLineId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetFireWoodOrderLine(forestOperationContractId, fireWoodOrderId, fireWoodOrderLineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get fresh contract pdf by id
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetFreshContractPdf(contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetFreshContractPdf(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calls portal endpoint.  Returns the list over the people who have right to sign for a property - JSON
     * @param {string} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetPropertyRightToSing(propertyId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetPropertyRightToSing(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information about wood for own use
     * @param {string} forestOperationContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractGetWoodForOwnUse(forestOperationContractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractGetWoodForOwnUse(forestOperationContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List firewood order lines
     * @param {string} forestOperationContractId 
     * @param {string} fireWoodOrderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractListFireWoodOrderLines(forestOperationContractId: string, fireWoodOrderId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractListFireWoodOrderLines(forestOperationContractId, fireWoodOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List firewood orders
     * @param {string} forestOperationContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractListFireWoodOrders(forestOperationContractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractListFireWoodOrders(forestOperationContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List production orders on contract
     * @param {string} forestOperationContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractListProductionOrders(forestOperationContractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractListProductionOrders(forestOperationContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List wood for own use orders
     * @param {string} forestOperationContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractListWoodForOwnUseOrders(forestOperationContractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractListWoodForOwnUseOrders(forestOperationContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Regenerate contract pdf by id
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractRegenerateContractPdf(contractId: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractRegenerateContractPdf(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update contract with a sorting number - available in contract listing
     * @param {string} contractId 
     * @param {number} sorting 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractSetContractSorting(contractId: string, sorting: number, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractSetContractSorting(contractId, sorting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update contract
     * @param {string} contractId 
     * @param {GmModelLibraryModelsContractModel} [gmModelLibraryModelsContractModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractUpdateContract(contractId: string, gmModelLibraryModelsContractModel?: GmModelLibraryModelsContractModel, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractUpdateContract(contractId, gmModelLibraryModelsContractModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change contract period
     * @param {string} contractDetailId 
     * @param {string} newPeriod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractUpdateContractDetailPeriodAction(contractDetailId: string, newPeriod: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractUpdateContractDetailPeriodAction(contractDetailId, newPeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change contract period
     * @param {string} contractId 
     * @param {string} newPeriod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractUpdateContractPeriodAction(contractId: string, newPeriod: string, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractUpdateContractPeriodAction(contractId, newPeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update firewood order
     * @param {string} forestOperationContractId 
     * @param {GmDtoLibraryDtoFireWoodOrderDto} [gmDtoLibraryDtoFireWoodOrderDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractUpdateFireWoodOrder(forestOperationContractId: string, gmDtoLibraryDtoFireWoodOrderDto?: GmDtoLibraryDtoFireWoodOrderDto, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractUpdateFireWoodOrder(forestOperationContractId, gmDtoLibraryDtoFireWoodOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update firewood order line
     * @param {string} forestOperationContractId 
     * @param {GmDtoLibraryDtoFirewoodOrderLineDto} [gmDtoLibraryDtoFirewoodOrderLineDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractUpdateFireWoodOrderLine(forestOperationContractId: string, gmDtoLibraryDtoFirewoodOrderLineDto?: GmDtoLibraryDtoFirewoodOrderLineDto, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractUpdateFireWoodOrderLine(forestOperationContractId, gmDtoLibraryDtoFirewoodOrderLineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update wood for own use order
     * @param {string} forestOperationContractId 
     * @param {GmDtoLibraryDtoContractWoodForOwnUseDto} [gmDtoLibraryDtoContractWoodForOwnUseDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractUpdateWoodForOwnUse(forestOperationContractId: string, gmDtoLibraryDtoContractWoodForOwnUseDto?: GmDtoLibraryDtoContractWoodForOwnUseDto, options?: RawAxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractUpdateWoodForOwnUse(forestOperationContractId, gmDtoLibraryDtoContractWoodForOwnUseDto, options).then((request) => request(this.axios, this.basePath));
    }
}

