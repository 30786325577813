<script>

export default {
  setup() {
    onMounted(() => {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
      const htmlTag = document.documentElement

      const updateClass = (e) => {
        if (e.matches) {
          htmlTag.classList.add('dark')
        } else {
          htmlTag.classList.remove('dark')
        }
      }

      // Set class initially
      updateClass(mediaQuery)

      // Update class when media query changes
      mediaQuery.addListener(updateClass)

      // Clean up event listener on unmount
      onUnmounted(() => {
        mediaQuery.removeListener(updateClass)
      })
    })
  }
}
</script>
<template>
  <slot />
</template>
