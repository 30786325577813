export const PROPERTY_MISREG_NOT_PARTICIPATED = 1
export const PROPERTY_MISREG_PARTICIPATED = 2
export const PROPERTY_MISREG_NOT_RELEVANT = 3
export const PROPERTY_MISREG_BELOW_100 = 4
export const PROPERTY_MISREG_BELOW_50 = 5

export const PROPERTY_MISREG_TYPES = [
  {
    Id: PROPERTY_MISREG_NOT_PARTICIPATED,
    Name: 'MISREG_NOT_PARTICIPATED'
  },
  {
    Id: PROPERTY_MISREG_PARTICIPATED,
    Name: 'MISREG_PARTICIPATED'
  },
  {
    Id: PROPERTY_MISREG_NOT_RELEVANT,
    Name: 'MISREG_NOT_RELEVANT'
  },
  {
    Id: PROPERTY_MISREG_BELOW_100,
    Name: 'MISREG_BELOW_100'
  },
  {
    Id: PROPERTY_MISREG_BELOW_50,
    Name: 'MISREG_BELOW_50'
  }
]

export const MANDATORY_SHARES_MULTIPLIER_HIGH = 0.18
export const MANDATORY_SHARES_MULTIPLIER_MEDIUM = 0.1
export const MANDATORY_SHARES_MULTIPLIER_LOW = 0.04

export const SHARE_PRICE_NOK = 125
export const MAGAZINE_SUBSCRIPTION_FEE = 250
export const MINIMUM_SHARE_QUANTITY = 10
export const YEARLY_SUBSCRIPTION_FEE = 900
