import {type AuthenticationResult, EventType, LogLevel, PublicClientApplication, type Configuration} from '@azure/msal-browser';
import {msalPlugin} from '~/plugins/auth/authVuePlugin';
import {CustomNavigationClient} from '~/plugins/auth/NavigationClient';
import {useRouter} from '#app';

/**
 * Setup based on https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-browser-samples/vue3-sample-app/src
 */
export default defineNuxtPlugin(async (nuxtApp) => {
    // Config object to be passed to Msal on creation
    const msalConfig: Configuration = {
        auth: {
            clientId: nuxtApp.$config.public.AUTH_CLIENT_ID,
            authority: `https://login.microsoftonline.com/${nuxtApp.$config.public.AUTH_TENANT_ID}`,
            redirectUri: '/', // Must be registered as a SPA redirectURI on your app registration
            postLogoutRedirectUri: '/' // Must be registered as a SPA redirectURI on your app registration
        },
        cache: {
            cacheLocation: 'localStorage'
        },
        system: {
            loggerOptions: {
                loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                },
                logLevel: LogLevel.Warning
            }
        }
    };

// Add here scopes for id token to be used at MS Identity Platform endpoints.
    const loginRequest = {
        scopes: ['openid', 'profile', 'api://e5f8da5a-397b-426d-a8d6-540c61993e91/user_impersonation'],
    };

// Add here the endpoints for MS Graph API services you would like to use.
    const graphConfig = {
        graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
    };

    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize()
    const router = useRouter();
    const navigationClient = new CustomNavigationClient(router);
    msalInstance.setNavigationClient(navigationClient);
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }
    msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });
    msalInstance.handleRedirectPromise().then(authResult => {
        // Check if user signed in
        const account = msalInstance.getActiveAccount();
        if (!account) {
            // redirect anonymous user to login page
            router.push('/login')
        } else {
            msalInstance.setActiveAccount(account);
            router.push(localStorage.getItem('sbl.currentRoute') || '/')
        }
    }).catch(err => {
        // TODO: Handle errors
        console.log(err);
    });

    nuxtApp.vueApp.use(msalPlugin, msalInstance)
    nuxtApp.provide('msal', msalInstance)

/*
    const authApi = {
        loggedIn: computed(() => msalInstance.getActiveAccount() !== null),
        logout() {
            console.log('todo: plugin logout')
        },
        async getAccessToken() {
            return (await msalInstance.acquireTokenSilent(loginRequest))?.accessToken
        },
        reset() {
            console.log('todo: plugin reset')
        },
        loginWith() {
            console.log('todo: plugin loginWith')
        },
        $storage: {
            getUniversal(key: string) {
                console.log('todo: plugin getUniversal')
                return key
            },
            setUniversal(key: string, value: any) {
                console.log('todo: plugin setUniversal')
                return key
            }
        }
    }
*/

    // nuxtApp.provide('auth', authApi);
})
