/* tslint:disable */
/* eslint-disable */
/**
 * Mjøsen Skog skogbruksleder API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { GmModelLibraryModelsContractsTimberModel } from '../model';
// @ts-ignore
import { GmModelLibraryModelsVsysShippedToRoadMessageModel } from '../model';
/**
 * TimberApi - axios parameter creator
 * @export
 */
export const TimberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create shipped to road message
         * @param {string} forestOperationContractId 
         * @param {GmModelLibraryModelsVsysShippedToRoadMessageModel} [gmModelLibraryModelsVsysShippedToRoadMessageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberCreateShippedToRoadMessage: async (forestOperationContractId: string, gmModelLibraryModelsVsysShippedToRoadMessageModel?: GmModelLibraryModelsVsysShippedToRoadMessageModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('timberCreateShippedToRoadMessage', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/timber/{forestOperationContractId}/shippedToRoad`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmModelLibraryModelsVsysShippedToRoadMessageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get delivery message by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetDeliveryMessage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('timberGetDeliveryMessage', 'id', id)
            const localVarPath = `/v1/delivered/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get measuring note by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetMeasuringNote: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('timberGetMeasuringNote', 'id', id)
            const localVarPath = `/v1/measuringNote/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get measuring ticket by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetMeasuringTicket: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('timberGetMeasuringTicket', 'id', id)
            const localVarPath = `/v1/measured/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get settlement report by voucher no
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetSettlementReport: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('timberGetSettlementReport', 'id', id)
            const localVarPath = `/v1/settlement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get timber by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimber: async (contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('timberGetTimber', 'contractId', contractId)
            const localVarPath = `/v1/timber/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get timber delivered by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberDelivered: async (forestOperationContractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('timberGetTimberDelivered', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/timber/{forestOperationContractId}/delivered`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get timber measured by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberMeasured: async (forestOperationContractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('timberGetTimberMeasured', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/timber/{forestOperationContractId}/measured`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get timber measured by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberMeasuredNotes: async (forestOperationContractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('timberGetTimberMeasuredNotes', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/timber/{forestOperationContractId}/measuredNotes`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get timber result by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberResult: async (forestOperationContractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('timberGetTimberResult', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/timber/{forestOperationContractId}/resultForestry`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get timber settlement by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberSettlement: async (forestOperationContractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('timberGetTimberSettlement', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/timber/{forestOperationContractId}/settlement`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Timber settlement report from Reporting service
         * @param {number} voucherNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberSettlementReportPdf: async (voucherNo: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voucherNo' is not null or undefined
            assertParamExists('timberGetTimberSettlementReportPdf', 'voucherNo', voucherNo)
            const localVarPath = `/v1/timberSettlement/{voucherNo}/pdf`
                .replace(`{${"voucherNo"}}`, encodeURIComponent(String(voucherNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get timber unmeasured by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberUnmeasured: async (forestOperationContractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('timberGetTimberUnmeasured', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/timber/{forestOperationContractId}/unmeasured`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set all delivered on contract
         * @param {string} forestOperationContractId 
         * @param {boolean} allDelivered 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberSetAllDeliveredContract: async (forestOperationContractId: string, allDelivered: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('timberSetAllDeliveredContract', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'allDelivered' is not null or undefined
            assertParamExists('timberSetAllDeliveredContract', 'allDelivered', allDelivered)
            const localVarPath = `/v1/timber/{forestOperationContractId}/updateAllDelivered/{allDelivered}`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"allDelivered"}}`, encodeURIComponent(String(allDelivered)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimberApi - functional programming interface
 * @export
 */
export const TimberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create shipped to road message
         * @param {string} forestOperationContractId 
         * @param {GmModelLibraryModelsVsysShippedToRoadMessageModel} [gmModelLibraryModelsVsysShippedToRoadMessageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberCreateShippedToRoadMessage(forestOperationContractId: string, gmModelLibraryModelsVsysShippedToRoadMessageModel?: GmModelLibraryModelsVsysShippedToRoadMessageModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberCreateShippedToRoadMessage(forestOperationContractId, gmModelLibraryModelsVsysShippedToRoadMessageModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberCreateShippedToRoadMessage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get delivery message by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberGetDeliveryMessage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberGetDeliveryMessage(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberGetDeliveryMessage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get measuring note by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberGetMeasuringNote(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberGetMeasuringNote(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberGetMeasuringNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get measuring ticket by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberGetMeasuringTicket(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberGetMeasuringTicket(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberGetMeasuringTicket']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get settlement report by voucher no
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberGetSettlementReport(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberGetSettlementReport(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberGetSettlementReport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get timber by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberGetTimber(contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsContractsTimberModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberGetTimber(contractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberGetTimber']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get timber delivered by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberGetTimberDelivered(forestOperationContractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberGetTimberDelivered(forestOperationContractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberGetTimberDelivered']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get timber measured by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberGetTimberMeasured(forestOperationContractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberGetTimberMeasured(forestOperationContractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberGetTimberMeasured']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get timber measured by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberGetTimberMeasuredNotes(forestOperationContractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberGetTimberMeasuredNotes(forestOperationContractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberGetTimberMeasuredNotes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get timber result by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberGetTimberResult(forestOperationContractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberGetTimberResult(forestOperationContractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberGetTimberResult']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get timber settlement by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberGetTimberSettlement(forestOperationContractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberGetTimberSettlement(forestOperationContractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberGetTimberSettlement']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Timber settlement report from Reporting service
         * @param {number} voucherNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberGetTimberSettlementReportPdf(voucherNo: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberGetTimberSettlementReportPdf(voucherNo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberGetTimberSettlementReportPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get timber unmeasured by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberGetTimberUnmeasured(forestOperationContractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberGetTimberUnmeasured(forestOperationContractId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberGetTimberUnmeasured']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Set all delivered on contract
         * @param {string} forestOperationContractId 
         * @param {boolean} allDelivered 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberSetAllDeliveredContract(forestOperationContractId: string, allDelivered: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberSetAllDeliveredContract(forestOperationContractId, allDelivered, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberApi.timberSetAllDeliveredContract']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TimberApi - factory interface
 * @export
 */
export const TimberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimberApiFp(configuration)
    return {
        /**
         * 
         * @summary Create shipped to road message
         * @param {string} forestOperationContractId 
         * @param {GmModelLibraryModelsVsysShippedToRoadMessageModel} [gmModelLibraryModelsVsysShippedToRoadMessageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberCreateShippedToRoadMessage(forestOperationContractId: string, gmModelLibraryModelsVsysShippedToRoadMessageModel?: GmModelLibraryModelsVsysShippedToRoadMessageModel, options?: any): AxiosPromise<string> {
            return localVarFp.timberCreateShippedToRoadMessage(forestOperationContractId, gmModelLibraryModelsVsysShippedToRoadMessageModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get delivery message by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetDeliveryMessage(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.timberGetDeliveryMessage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get measuring note by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetMeasuringNote(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.timberGetMeasuringNote(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get measuring ticket by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetMeasuringTicket(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.timberGetMeasuringTicket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get settlement report by voucher no
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetSettlementReport(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.timberGetSettlementReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get timber by id
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimber(contractId: string, options?: any): AxiosPromise<GmModelLibraryModelsContractsTimberModel> {
            return localVarFp.timberGetTimber(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get timber delivered by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberDelivered(forestOperationContractId: string, options?: any): AxiosPromise<string> {
            return localVarFp.timberGetTimberDelivered(forestOperationContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get timber measured by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberMeasured(forestOperationContractId: string, options?: any): AxiosPromise<string> {
            return localVarFp.timberGetTimberMeasured(forestOperationContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get timber measured by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberMeasuredNotes(forestOperationContractId: string, options?: any): AxiosPromise<string> {
            return localVarFp.timberGetTimberMeasuredNotes(forestOperationContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get timber result by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberResult(forestOperationContractId: string, options?: any): AxiosPromise<string> {
            return localVarFp.timberGetTimberResult(forestOperationContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get timber settlement by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberSettlement(forestOperationContractId: string, options?: any): AxiosPromise<string> {
            return localVarFp.timberGetTimberSettlement(forestOperationContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Timber settlement report from Reporting service
         * @param {number} voucherNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberSettlementReportPdf(voucherNo: number, options?: any): AxiosPromise<string> {
            return localVarFp.timberGetTimberSettlementReportPdf(voucherNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get timber unmeasured by id
         * @param {string} forestOperationContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberGetTimberUnmeasured(forestOperationContractId: string, options?: any): AxiosPromise<string> {
            return localVarFp.timberGetTimberUnmeasured(forestOperationContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set all delivered on contract
         * @param {string} forestOperationContractId 
         * @param {boolean} allDelivered 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberSetAllDeliveredContract(forestOperationContractId: string, allDelivered: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.timberSetAllDeliveredContract(forestOperationContractId, allDelivered, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimberApi - object-oriented interface
 * @export
 * @class TimberApi
 * @extends {BaseAPI}
 */
export class TimberApi extends BaseAPI {
    /**
     * 
     * @summary Create shipped to road message
     * @param {string} forestOperationContractId 
     * @param {GmModelLibraryModelsVsysShippedToRoadMessageModel} [gmModelLibraryModelsVsysShippedToRoadMessageModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberCreateShippedToRoadMessage(forestOperationContractId: string, gmModelLibraryModelsVsysShippedToRoadMessageModel?: GmModelLibraryModelsVsysShippedToRoadMessageModel, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberCreateShippedToRoadMessage(forestOperationContractId, gmModelLibraryModelsVsysShippedToRoadMessageModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get delivery message by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberGetDeliveryMessage(id: string, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberGetDeliveryMessage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get measuring note by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberGetMeasuringNote(id: string, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberGetMeasuringNote(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get measuring ticket by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberGetMeasuringTicket(id: string, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberGetMeasuringTicket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get settlement report by voucher no
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberGetSettlementReport(id: number, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberGetSettlementReport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get timber by id
     * @param {string} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberGetTimber(contractId: string, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberGetTimber(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get timber delivered by id
     * @param {string} forestOperationContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberGetTimberDelivered(forestOperationContractId: string, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberGetTimberDelivered(forestOperationContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get timber measured by id
     * @param {string} forestOperationContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberGetTimberMeasured(forestOperationContractId: string, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberGetTimberMeasured(forestOperationContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get timber measured by id
     * @param {string} forestOperationContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberGetTimberMeasuredNotes(forestOperationContractId: string, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberGetTimberMeasuredNotes(forestOperationContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get timber result by id
     * @param {string} forestOperationContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberGetTimberResult(forestOperationContractId: string, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberGetTimberResult(forestOperationContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get timber settlement by id
     * @param {string} forestOperationContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberGetTimberSettlement(forestOperationContractId: string, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberGetTimberSettlement(forestOperationContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Timber settlement report from Reporting service
     * @param {number} voucherNo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberGetTimberSettlementReportPdf(voucherNo: number, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberGetTimberSettlementReportPdf(voucherNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get timber unmeasured by id
     * @param {string} forestOperationContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberGetTimberUnmeasured(forestOperationContractId: string, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberGetTimberUnmeasured(forestOperationContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set all delivered on contract
     * @param {string} forestOperationContractId 
     * @param {boolean} allDelivered 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberApi
     */
    public timberSetAllDeliveredContract(forestOperationContractId: string, allDelivered: boolean, options?: RawAxiosRequestConfig) {
        return TimberApiFp(this.configuration).timberSetAllDeliveredContract(forestOperationContractId, allDelivered, options).then((request) => request(this.axios, this.basePath));
    }
}

