import { PROPERTY_MISREG_NOT_PARTICIPATED } from '../properties/constants'
import { generateUUID } from '../uuid/util'
import { ENVIRONMENTAL_AGREEMENT_STATES_DRAFT } from './constants'
import type {EnvironmentalAgreement} from '~/models/environmentalAgreement'
import type {Customer, Property} from '~/models/customers'

export const setDefaultEnvironmentalAgreementData = (
  customer: Customer
): EnvironmentalAgreement => {
  const agreement: EnvironmentalAgreement = {
    Id: 'temp_' + generateUUID(),
    Status: ENVIRONMENTAL_AGREEMENT_STATES_DRAFT,
    ForestOwnerId: customer.Id,
    Course: customer.ContactList.some((c) => c.PEFC),
    ForestManagementPlan: true,
    Details: []
  }

  customer.Properties.forEach((property: Property) => {
    agreement.Details.push({
      Id: 'temp_' + generateUUID(),
      PropertyId: property.Id,
      PropertyName: property.Name,
      ForestAreaProd: property.ForestAreaProd,
      ForestAreaTot: property.TotalArea,
      LandscapePlan: property.LandscapePlan,
      EnvironmentalInventoryMIS: property.MisReg ? property.MisReg : PROPERTY_MISREG_NOT_PARTICIPATED,
      KeyHabitat: property.KeyHabitat
    })
  })

  return agreement
}
