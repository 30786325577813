import { defineNuxtPlugin } from '#app'
import { registerSW } from 'virtual:pwa-register'

/**
 * Needed for autoUpdate to function properly.
 * https://vite-pwa-org.netlify.app/guide/auto-update.html
 *
 * Tip: You can test the autoUpdate functionality by running npm build, then npm preview.
 * Make some changes, run npm build and preview again, and refresh the page.
 */
export default defineNuxtPlugin(() => {
    registerSW({ immediate: true })
})
