import orderBy from 'lodash/orderBy'
import type {Contract, FullContract} from '@/models/contract'
import type {Customer, Property} from '@/models/customers'
import {CONTRACT_COMPLETIONS, CONTRACT_PERIODS, CONTRACT_STATES} from '@/constants/contract'
import {useEntrepreneursStore} from '@/use/entrepreneurs/store'
import {customersDb} from './indexDB'

type ContractTypeWithYearAndMonth = Pick<Contract, 'Year' | 'LoggingMonth' | 'Status' | 'Id' | 'ForestOwnerName'>

export function getContractsByDate <T extends ContractTypeWithYearAndMonth> (contracts: T[]): { [key: string]: { [month: number]: T[] } } {
  return orderBy(contracts, ['Status', 'ForestOwnerName']).reduce((acc, contract) => ({
    ...acc,
    [contract.Year || '']: {
      ...acc[contract.Year!],
      [contract.LoggingMonth || '']: [
        ...((acc[contract.Year!] && acc[contract.Year!][contract.LoggingMonth!]) || []),
        contract
      ]
    }
  }), {} as { [key: string]: { [month: number]: T[] } })
}

export function getContractBaseUrl (contract: Contract | FullContract): string {
  return `/customers/${contract.ForestOwnerId}/${contract.PropertyId}/${contract.Id}`
}

export async function getContractCustomer (contract: Contract): Promise<Customer | undefined> {
  if (!contract.ForestOwnerId) {
    return {}
  }
  try {
    return await customersDb.getById(contract.ForestOwnerId)
  } catch (error) {
    console.error(error)
  }
}

export async function getContractProperty (contract: Contract): Promise<Property | undefined> {
  try {
    const customer = await getContractCustomer(contract)
    return customer && contract && contract.PropertyId ? customer.Properties?.find(p => p.Id === contract.PropertyId) : undefined
  } catch (error) {
    console.error(error)
  }
}

export function getEntrepreneurNameById (id: string): string {
  const { getNameById } = useEntrepreneursStore()
  return getNameById(id)
}

export async function getForestOwnerNameById (id: string): Promise<string | undefined> {
  try {
    const customer = await customersDb.getById(id)
    return customer.Name
  } catch (error) {
    console.error(error)
  }
}

export function getContractStateLabel (contract: Contract): string {
  return CONTRACT_STATES.find(s => s.Id === contract.Status) ? CONTRACT_STATES.find(s => s.Id === contract.Status)?.Name || '' : ''
}

export function getContractPeriodLabel (contract: FullContract): string {
  return CONTRACT_PERIODS.find(s => s.Id === contract.Period) ? CONTRACT_PERIODS.find(s => s.Id === contract.Period)?.Name || '' : ''
}

export function getContractCompletionLabel (contract: FullContract): string {
  return CONTRACT_COMPLETIONS.find(s => s.Id === contract.AssortmentInformation?.CompleteOperationTarget) ? CONTRACT_COMPLETIONS.find(s => s.Id === contract.AssortmentInformation?.CompleteOperationTarget)?.Name || '' : ''
}

export const calculateTopplePlaceVolume = (contract: FullContract): number => {
  if (contract.AssortmentInformation && contract.AssortmentInformation.TotalWoodForSale) {
    let volume = contract.AssortmentInformation.TotalWoodForSale
    contract.TopplePlaces?.forEach((place) => {
      if (place.Capacity) {
        volume -= place.Capacity
      }
    })
    return volume
  } else {
    return 0
  }
}

export function isTempContract(id: string): boolean {
  return /^temp_.+/.test(id.toString())
}
