import { openDB } from 'idb'
import type {SblDB} from './dbSchema'

const dbPromise = openDB<SblDB>('SBL', 9, {
  upgrade (db, oldVersion, _, transaction) {
    if (oldVersion < 4) {
      const customerStore = db.createObjectStore('customers', {
        keyPath: 'Id'
      })
      customerStore.createIndex('by-name', 'Name')

      const contractStore = db.createObjectStore('contracts', {
        keyPath: 'Id'
      })
      contractStore.createIndex('by-type', 'Type', {
        unique: false
      })

      const draftStore = db.createObjectStore('drafts', {
        keyPath: 'Id'
      })
      draftStore.createIndex('by-type', 'Type', {
        unique: false
      })

      db.createObjectStore('queue', {
        keyPath: 'Id'
      })
    }

    if (oldVersion < 5) {
      db.createObjectStore('managers', {
        keyPath: 'Id'
      })
    }

    if (oldVersion < 6) {
      const contractStore = transaction.objectStore('contracts')
      contractStore.createIndex('by-property', 'ForestOwnerId', {
        unique: false
      })
      const draftStore = transaction.objectStore('drafts')
      draftStore.createIndex('by-property', 'ForestOwnerId', {
        unique: false
      })
    }

    if (oldVersion < 7) {
      db.createObjectStore('cache')
    }

    if (oldVersion < 7) {
      db.createObjectStore('powerOfAttorneysByProperty', {
        keyPath: 'propertyId'
      })
    }

    if (oldVersion < 8) {
      const noteStore = db.createObjectStore('notes', {
        keyPath: 'LocalId'
      })
      noteStore.createIndex('by-forest-owner', 'ForestOwnerId', {
        unique: false
      })
      noteStore.createIndex('by-id', 'Id', {
        unique: true
      })
      noteStore.createIndex('by-status', 'SyncStatus', {
        unique: false
      })
    }
    if (oldVersion < 9) {
      const noteStore = transaction.objectStore('notes')
      noteStore.createIndex('by-contract', 'ContractId', {
        unique: false
      })
      noteStore.createIndex('by-property', 'PropertyId', {
        unique: false
      })
    }
  },
  blocked () {
    console.error('Database is blocked, Called if there are older versions of the database open on the origin, so this version cannot open.')
  },
  blocking () {
    console.error('Database is blocking, Called if this connection is blocking a future version of the database from opening. ')
  },
  terminated () {
    console.error('Database connection got abnormally terminated by browser.')
  }
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDb = () => ({
  dbPromise
})
