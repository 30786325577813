
import { orderBy } from 'lodash'
import { useLocalstorage } from '../localStorage/store'
import type {Entrepreneur} from '~/models/entrepreneurs'
import type {Favorite} from '~/models/favorites'
import {useAxiosClient} from "~/use/axios/client";
import {EntrepreneurApi} from "~/gen/openapi/sblService";

const { persist } = useLocalstorage()

const state = reactive({
  entrepreneurs: persist<Entrepreneur[]>('entrepreneurs', []),
  favorites: persist('favoriteEntrepreneurs', [] as Favorite[]),
  lastFetchedEntrepreneurs: persist<number>('lastFetchedEntrepreneurs', 0),
})

const entrepreneurs = computed((): Entrepreneur[] => orderBy(state.entrepreneurs, 'ParentName'))
const favorites = computed((): Favorite[] => orderBy(state.favorites, 'count', 'desc').slice(0, 10))
const lastFetchedEntrepreneurs = computed((): number => state.lastFetchedEntrepreneurs)
const getNameById = (id: string): string => state.entrepreneurs.find(e => e.Id === id)?.Name || ''

const clearEntrepreneurs = (): void => {
  state.entrepreneurs = []
  state.favorites = []
}

const setEntrepreneurs = (entrepreneurs: Entrepreneur[]): void => {
  state.entrepreneurs = entrepreneurs
}
const addToFavorites = (id: string): void => {
  const existing = state.favorites.find((x: Favorite) => x.id === id)
  if (existing) {
    state.favorites[state.favorites.indexOf(existing)].count++
  } else {
    state.favorites.push({
      id,
      count: 1
    })
  }
}

const loadEntrepreneurs = async (force?: boolean): Promise<void> => {
  if (!force &&
      state.entrepreneurs.length &&
      lastFetchedEntrepreneurs &&
      lastFetchedEntrepreneurs.value > Date.now() - 1000 /*ms*/ * 60 /*seconds*/ * 60 /*minutes*/
  ) {
    return
  }
  try {
    const { axiosClient } = useAxiosClient()
    const entrepreneurApi = new EntrepreneurApi(undefined, '', axiosClient.value)
    const entrepreneurs: Entrepreneur[] = (await entrepreneurApi.entrepreneurGetEntrepreneurs())?.data
    setEntrepreneurs(entrepreneurs)
    state.lastFetchedEntrepreneurs = Date.now()
  } catch (e) {
    console.error('Failed to load entrepreneurs', e)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useEntrepreneursStore = () => ({
  clearEntrepreneurs,
  entrepreneurs,
  favorites,
  getNameById,
  addToFavorites,
  loadEntrepreneurs
})
