import type {AxiosInstance} from 'axios';


const instance = reactive({
  client: {} as AxiosInstance
})

function setClient (client: AxiosInstance): void {
  instance.client = client
}

const axiosClient = computed<AxiosInstance>(() => instance.client)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAxiosClient = () => ({
  setClient,
  axiosClient
})
