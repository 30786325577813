import type {AxiosResponse} from 'axios';
import { useLocalstorage } from '../localStorage/store'
import type {
  Customer,
  Delivered,
  DeliveryDocument, Measured,
  MeasuringNote,
  ResultForestry, Settlement,
  SettlementDetail, SettlementReport, ShippedToRoadLine,
  Timber
} from '~/models/timber';
import {TimberApi} from "~/gen/openapi/sblService";
import {useAxiosClient} from "~/use/axios/client";

const { persist } = useLocalstorage()

const state = reactive({
  currentDeliveryMessage: persist<DeliveryDocument>('currentDeliveryMessage', null),
  currentMeasuringTicket: persist<MeasuringNote>('currentMeasuringTicket', null),
  currentSettlementDetail: persist<SettlementDetail>('currentSettlementDetail', null),
  currentTimber: persist<Timber>('currentTimber', null),
  currentTimberCustomer: persist<Customer>('currentTimberCustomer', null)
})

const currentDeliveryMessage = computed((): DeliveryDocument => state.currentDeliveryMessage)
const currentSettlementDetail = computed((): SettlementDetail => state.currentSettlementDetail)
const currentMeasuringTicket = computed((): MeasuringNote => state.currentMeasuringTicket)
const currentTimber = computed((): Timber => state.currentTimber)
const currentTimberCustomer = computed((): Customer => state.currentTimberCustomer)

const clearCurrentTimber = (): void => {
  state.currentDeliveryMessage = null
  state.currentMeasuringTicket = null
  state.currentTimber = null
  state.currentTimberCustomer = null
  state.currentSettlementDetail = null
}

const setTimber = (value: Timber): void => {
  // Skip if new timber contract entirely
  if (currentTimber.value && currentTimber.value.Id && currentTimber.value.Id === value.Id) {
    const fields = ['Delivered', 'Unmeasured', 'Measured', 'Settlement', 'ResultForestry']

    for (const field of fields) {
      if (currentTimber.value[field]) {
        value[field] = currentTimber.value[field]
      }
    }
  }
  state.currentTimber = value
}

/**
 * Timber API request responses are strings as they are fetched from portal.
 * They can be empty strings, which may crash when parsing.
 * @param response
 */
const safelyParseAxiosResponseToJSON = (response: AxiosResponse<object, any>) => {
  const responseString = response.data?.toString()
  if (responseString?.length > 1) {
    if (responseString.includes('{') && responseString.includes('}')) {
      return {...response, data: JSON.parse(responseString)}
    }
    return {...response, data: {...response.data, message: responseString }}
  }
  return {...response, data: {}}
}

export async function fetchDelivered (contractId: string): Promise<Delivered> {
  const { axiosClient } = useAxiosClient()
  const timberApi = new TimberApi(undefined, '', axiosClient.value)
  try {
    const response: Delivered = safelyParseAxiosResponseToJSON(await timberApi.timberGetTimberDelivered(contractId)).data
    state.currentTimber = {
        ...state.currentTimber,
        Delivered: response
    }
    return response
  } catch (error) {
    console.error(error)
    return Promise.reject(error)
  }
}

export async function fetchDeliveryDocument (contractId: string): Promise<DeliveryDocument> {
  const { axiosClient } = useAxiosClient()
  const timberApi = new TimberApi(undefined, '', axiosClient.value)
  try {
    const response: DeliveryDocument = safelyParseAxiosResponseToJSON(await timberApi.timberGetDeliveryMessage(contractId)).data
    state.currentDeliveryMessage = response
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchMeasured (contractId: string): Promise<Measured> {
  const { axiosClient } = useAxiosClient()
  const timberApi = new TimberApi(undefined, '', axiosClient.value)
  try {
    const response: Measured = safelyParseAxiosResponseToJSON(await timberApi.timberGetTimberMeasuredNotes(contractId)).data
    state.currentTimber.Measured = response
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchMeasuringNote (contractId: string): Promise<MeasuringNote> {
  const { axiosClient } = useAxiosClient()
  const timberApi = new TimberApi(undefined, '', axiosClient.value)
  try {
    const response: MeasuringNote = safelyParseAxiosResponseToJSON(await timberApi.timberGetMeasuringNote(contractId)).data
    state.currentMeasuringTicket = response
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchResult (contractId: string): Promise<ResultForestry> {
  const { axiosClient } = useAxiosClient()
  const timberApi = new TimberApi(undefined, '', axiosClient.value)
  try {
    const response: ResultForestry = safelyParseAxiosResponseToJSON(await timberApi.timberGetTimberResult(contractId)).data
    state.currentTimber = {
        ...state.currentTimber,
        ResultForestry: response
    }
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchSettlementReport (settlementNumber: number): Promise<SettlementReport> {
  const { axiosClient } = useAxiosClient()
  const timberApi = new TimberApi(undefined, '', axiosClient.value)
  try {
    return safelyParseAxiosResponseToJSON(await timberApi.timberGetSettlementReport(settlementNumber)).data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchSettlements (contractId: string): Promise<Settlement> {
  const { axiosClient } = useAxiosClient()
  const timberApi = new TimberApi(undefined, '', axiosClient.value)
  try {
    const response: Settlement = safelyParseAxiosResponseToJSON(await timberApi.timberGetTimberSettlement(contractId)).data
    state.currentTimber.Settlement = response
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchTimber (contractId: string): Promise<Timber> {
  const { axiosClient } = useAxiosClient()
  const timberApi = new TimberApi(undefined, '', axiosClient.value)
  try {
    const response: Timber = safelyParseAxiosResponseToJSON(await timberApi.timberGetTimber(contractId)).data
    setTimber(response)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchUnmeasured (contractId: string): Promise<Delivered> {
  const { axiosClient } = useAxiosClient()
  const timberApi = new TimberApi(undefined, '', axiosClient.value)
  try {
    const response: Delivered = safelyParseAxiosResponseToJSON(await timberApi.timberGetTimberUnmeasured(contractId)).data
    state.currentTimber.Unmeasured = response
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateAllDelivered (contractId: string, value: boolean): Promise<string> {
  const { axiosClient } = useAxiosClient()
  const timberApi = new TimberApi(undefined, '', axiosClient.value)
  try {
    const response: AxiosResponse<string> = safelyParseAxiosResponseToJSON(await timberApi.timberSetAllDeliveredContract(contractId, value))
    if (response.status === 200) {
      state.currentTimber.TimberLot.EverythingDeliveredToRoad = value
      state.currentTimber.TimberLot.EndDate = value ? new Date() : null
    }
    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

export async function updateTimberlot (contractId: string, data: ShippedToRoadLine): Promise<Timber> {
  const { axiosClient } = useAxiosClient()
  const timberApi = new TimberApi(undefined, '', axiosClient.value)
  try {
    const response: Timber = safelyParseAxiosResponseToJSON(await timberApi.timberCreateShippedToRoadMessage(contractId, data)).data
    state.currentTimber = response
    return response
  } catch (error) {
    throw new Error(error)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTimberStore = () => ({
  clearCurrentTimber,
  currentDeliveryMessage,
  currentMeasuringTicket,
  currentSettlementDetail,
  currentTimber,
  currentTimberCustomer,
  fetchDelivered,
  fetchDeliveryDocument,
  fetchMeasured,
  fetchMeasuringNote,
  fetchResult,
  fetchSettlementReport,
  fetchSettlements,
  fetchTimber,
  fetchUnmeasured,
  updateAllDelivered,
  updateTimberlot
})
