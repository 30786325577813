import { useDb } from '~/use/indexedDb/setup'
import cloneDeep from 'lodash/cloneDeep';

const { dbPromise } = useDb()

const cacheDb = {
  async put<T> (key: string, value: T): Promise<string> {
    try {
      return (await dbPromise).put('cache', cloneDeep(value), key)
    } catch (e) {
      console.error('cacheDb: put', e)
    }
  },

  async get<T> (key: string): Promise<T | undefined> {
    try {
      return (await dbPromise).get('cache', key)
    } catch (e) {
      console.error('cacheDb: get', e)
    }
  },

  async clear(): Promise<void> {
    try {
      await (await dbPromise).clear('cache')
    } catch (e) {
      console.error('cacheDb: clear', e)
    }
  }
}

export const useCacheDb = () => ({
  cacheDb
})
