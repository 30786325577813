import { useDb } from './setup'
import type {Customer} from '~/models/customers';
import cloneDeep from 'lodash/cloneDeep';

const { dbPromise } = useDb()

const insertCustomers = async (customers: Customer[]): Promise<boolean> => {
  try {
    // (await dbPromise).clear('customers')
    const tx = (await dbPromise).transaction('customers', 'readwrite')
    const store = tx.objectStore('customers')
    customers.forEach((customer) => {
      if (customer.ToBeRemoved) {
        tx.store.delete(customer.Id)
      } else {
        store.put(customer)
      }
    })
    await tx.done
    return true
  } catch (error) {
    console.error('insert Customer error', error)
    return false
  }
}

const customersDb = {
  async getById (id: string): Promise<Customer | undefined> {
    try {
      return (await dbPromise).get('customers' as never, id)
    } catch (error) {
      console.error('customersDb: getById', error)
    }
  },
  async getAll (): Promise<Customer[] | undefined> {
    try {
      return (await dbPromise).getAll('customers')
    } catch (error) {
      console.error('customersDb: getAll', error)
    }
  },
  async getByName (): Promise<Customer[] | undefined> {
    try {
      return (await dbPromise).getAllFromIndex('customers', 'by-name')
    } catch (error) {
      console.error('customersDb: getByName', error)
    }
  },
  async setById (customer: Customer): Promise<IDBValidKey | undefined> {
    try {
      return (await dbPromise).put('customers', cloneDeep(customer))
    } catch (error) {
      console.error('customersDb: setById', error)
    }
  },
  async delete (id: Customer['Id']): Promise<void> {
    try {
      return (await dbPromise).delete('customers', id)
    } catch (error) {
      console.error('customersDb: delete', error)
    }
  },
  async clear (): Promise<void> {
    try {
      return (await dbPromise).clear('customers')
    } catch (error) {
      console.error('customersDb: clear', error)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCustomersDb = () => ({
  customersDb,
  insertCustomers
})
