export default defineNuxtRouteMiddleware((to, from) => {
    const {$msal} = useNuxtApp()

    if (['/loading', '/login', '/'].includes(to.path) === false) {
        console.log('setting currentRoute to ', to.path)
        localStorage.setItem('sbl.currentRoute', to.path)
    }

    if (!$msal.getActiveAccount() && to.path !== '/login') {
        return navigateTo('/login')
    }
})
