import { CONTRACT_PERIODS } from '../constants'
import type {ActionPlan, FullContract} from '~/models/contract'
import {
  ALLMA_JOB_TYPE_ADDITIVE_PLANTING,
  ALLMA_JOB_TYPE_DITCH_CLEARING,
  ALLMA_JOB_TYPE_FERTILIZATION,
  ALLMA_JOB_TYPE_PLANTATION_CONTROL,
  ALLMA_JOB_TYPE_PLANTING,
  ALLMA_JOB_TYPE_PRECLEARING_FELLING,
  ALLMA_JOB_TYPE_PRECLEARING_THINNING,
  ALLMA_JOB_TYPE_SCARIFICATION,
  ALLMA_JOB_TYPE_SCARIFICATION_WITH_EXCAVATOR,
  ALLMA_JOB_TYPE_SPRAYING,
  ALLMA_JOB_TYPE_STEMPRUNING,
  ALLMA_JOB_TYPE_YOUNG_TREE_CARE
} from '~/constants/allmaJobs'

export const getDefaultPlantingDate = (contract: FullContract): Date => {
  const date = new Date()
  if (contract.LoggingMonth <= 3) { date.setFullYear(contract.Year, 3, 1) } else if (contract.LoggingMonth <= 7) { date.setFullYear(contract.Year, 7, 1) } else { date.setFullYear(contract.Year + 1, 3, 1) }
  return date
}

const getDefaultPlantationControlDate = (contract: FullContract): Date => {
  const date = new Date()
  date.setFullYear(contract.Year + 2, contract.LoggingMonth, 1)
  return date
}

const getDefaultScarificationDate = (contract: FullContract): Date => {
  const date = new Date()
  contract.LoggingMonth <= 6 ? date.setFullYear(contract.Year, 7, 1) : date.setFullYear(contract.Year + 1, 5, 1)
  return date
}

const getDefaultScarificationWithExcavatorDate = (contract: FullContract): Date => {
  const date = new Date()
  if (contract.LoggingMonth <= 5) { date.setFullYear(contract.Year, 4, 1) } else if (contract.LoggingMonth <= 9) { date.setFullYear(contract.Year, contract.LoggingMonth, 1) } else { date.setFullYear(contract.Year + 1, 4, 1) }
  return date
}

export const getDefaultDitchClearingDate = (contract: FullContract): Date => {
  const date = new Date()
  if (contract.Period === 1) {
    contract.LoggingMonth === 12 ? date.setFullYear(contract.Year + 1, 4, 1) : date.setFullYear(contract.Year, 4, 1)
  } else if (contract.Period === 2) {
    date.setFullYear(contract.Year, 7, 1)
  } else if (contract.Period === 3) {
    date.setFullYear(contract.Year, 10, 1)
  } else {
    date.setFullYear(contract.Year + 1, 3, 1)
  }
  return date
}

const getDefaultSprayingDate = (contract: FullContract): Date => {
  const date = new Date()
  contract.LoggingMonth <= 7 ? date.setFullYear(contract.Year, 7, 1) : date.setFullYear(contract.Year + 1, 7, 1)
  return date
}

const getDefaultYoungTreecareDate = (contract: FullContract): Date => {
  const date = new Date()
  if (contract.LoggingMonth <= 3) { date.setFullYear(contract.Year, 3, 1) } else if (contract.LoggingMonth <= 10) { date.setFullYear(contract.Year, contract.LoggingMonth, 1) } else { date.setFullYear(contract.Year + 1, 3, 1) }
  return date
}

const getDefaultStempruningDate = (contract: FullContract): Date => {
  const date = new Date()
  date.setFullYear(contract.Year + 1, 0, 1)
  return date
}

const getDefaultFertilizationDate = (contract: FullContract): Date => {
  const date = new Date()
  contract.LoggingMonth <= 3 ? date.setFullYear(contract.Year, 3, 1) : date.setFullYear(contract.Year + 1, 3, 1)
  return date
}

const getDefaultPreclearingFellingDate = (): Date => {
  const date = new Date()
  return date
}

const getDefaultPreclearingThinningDate = (): Date => {
  const date = new Date()
  return date
}

export const setDefaultDeliveryDates = (actionplan: ActionPlan, contract: FullContract): ActionPlan => {
  actionplan.PlantingDate = actionplan.PlantingDate || getDefaultPlantingDate(contract).toISOString()
  actionplan.ScarificationDate = actionplan.ScarificationDate || getDefaultScarificationDate(contract).toISOString()
  actionplan.ScarificationWithExcavatorDate = actionplan.ScarificationWithExcavatorDate || getDefaultScarificationWithExcavatorDate(contract).toISOString()
  actionplan.DitchClearingDate = actionplan.DitchClearingDate || getDefaultDitchClearingDate(contract).toISOString()
  actionplan.SprayingDate = actionplan.SprayingDate || getDefaultSprayingDate(contract).toISOString()
  actionplan.YoungTreecareDate = actionplan.YoungTreecareDate || getDefaultYoungTreecareDate(contract).toISOString()
  actionplan.StempruningDate = actionplan.StempruningDate || getDefaultStempruningDate(contract).toISOString()
  actionplan.FertilizationDate = actionplan.FertilizationDate || getDefaultFertilizationDate(contract).toISOString()
  actionplan.PreclearingBeforeFinalFellingDate = actionplan.PreclearingBeforeFinalFellingDate || getDefaultPreclearingFellingDate().toISOString()
  actionplan.PreclearingBeforeThinningDate = actionplan.PreclearingBeforeThinningDate || getDefaultPreclearingThinningDate().toISOString()
  actionplan.PlantationControlDate = actionplan.PlantationControlDate || getDefaultPlantationControlDate(contract).toISOString()
  return actionplan
}

export const setCalculatedYearMonthAndPeriod = (contract: FullContract): FullContract => {
  const now = new Date()
  contract.Year = now.getFullYear()
  contract.LoggingMonth = now.getMonth() + 1
  if (contract.LoggingMonth === 1 || contract.LoggingMonth === 2 || contract.LoggingMonth === 3 || (contract.LoggingMonth === 12 && now.getDate() > 15) || (contract.LoggingMonth === 4 && now.getDate() < 16)) {
    contract.Period = 1
  } else if (contract.LoggingMonth === 5 || contract.LoggingMonth === 6 || (contract.LoggingMonth === 4 && now.getDate() > 15) || (contract.LoggingMonth === 7 && now.getDate() < 16)) {
    contract.Period = 2
  } else if (contract.LoggingMonth === 8 || contract.LoggingMonth === 9 || (contract.LoggingMonth === 7 && now.getDate() > 15) || (contract.LoggingMonth === 10 && now.getDate() < 16)) {
    contract.Period = 3
  } else {
    contract.Period = 4
  }
  return contract
}

export const updateDateWithYearAndMonth = (date: Date | string | undefined, month: number, year: number): Date => {
  const _date: Date = new Date(date)
  if (month) { _date.setMonth(month) }
  if (year) { _date.setFullYear(year) }
  return _date
}

export const getMonthFromDate = (date: Date | string | undefined): number => {
  return new Date(date).getMonth()
}

export const getYearFromDate = (date: Date | string | undefined): number => {
  return new Date(date).getFullYear()
}

export const getMonthName = (index: number): string => {
  const monthNames = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember']
  // month = Number(month)
  return monthNames[index]
}

export const getDefaultActionDate = (jobType: string, contract: FullContract): Date | null => {
  switch (jobType) {
    case ALLMA_JOB_TYPE_PLANTING:
      return getDefaultPlantingDate(contract)
    case ALLMA_JOB_TYPE_SCARIFICATION:
      return getDefaultScarificationDate(contract)
    case ALLMA_JOB_TYPE_SCARIFICATION_WITH_EXCAVATOR:
      return getDefaultScarificationWithExcavatorDate(contract)
    case ALLMA_JOB_TYPE_DITCH_CLEARING:
      return getDefaultDitchClearingDate(contract)
    case ALLMA_JOB_TYPE_SPRAYING:
      return getDefaultSprayingDate(contract)
    case ALLMA_JOB_TYPE_FERTILIZATION:
      return getDefaultFertilizationDate(contract)
    case ALLMA_JOB_TYPE_YOUNG_TREE_CARE:
      return getDefaultYoungTreecareDate(contract)
    case ALLMA_JOB_TYPE_STEMPRUNING:
      return getDefaultStempruningDate(contract)
    case ALLMA_JOB_TYPE_ADDITIVE_PLANTING:
      return getDefaultPlantingDate(contract)
    case ALLMA_JOB_TYPE_PRECLEARING_FELLING:
      return getDefaultPreclearingFellingDate()
    case ALLMA_JOB_TYPE_PRECLEARING_THINNING:
      return getDefaultPreclearingThinningDate()
    case ALLMA_JOB_TYPE_PLANTATION_CONTROL:
      return getDefaultPlantationControlDate(contract)
  }
  return null
}

export const getContractPeriodLabel = (contract: FullContract): string => {
  return CONTRACT_PERIODS.find(s => s.Id === contract.Period) ? CONTRACT_PERIODS.find(s => s.Id === contract.Period).Name : ''
}
