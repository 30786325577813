import type {Customer} from '@/models/customers'
import type {Contract, FullContract} from '@/models/contract'
import type {QueueItem} from '@/models/queue'
import { useDb } from '~/use/indexedDb/setup'
import cloneDeep from 'lodash/cloneDeep';

const { dbPromise } = useDb()

export const contractsDb = {
  async getById (id: string): Promise<Contract> {
    try {
      return (await dbPromise).get('contracts', id)
    } catch (error) {
      console.error('contractsDb: getById', error)
    }
  },
  async getAll (): Promise<Contract[]> {
    try {
      return (await dbPromise).getAll('contracts')
    } catch (error) {
      console.error('contractsDb: getAll', error)
    }
  },
  async getByType (type: number): Promise<Contract[]> {
    try {
      return (await dbPromise).getAllFromIndex('contracts', 'by-type', type)
    } catch (error) {
      console.error('contractsDb: getByType', error)
    }
  },
  async setById (contract: Contract): Promise<string> {
    try {
      return (await dbPromise).put('contracts', cloneDeep(contract))
    } catch (error) {
      console.error('contractsDb: setById', error)
    }
  },
  async delete (id: Contract['Id']): Promise<void> {
    try {
      return (await dbPromise).delete('contracts', id)
    } catch (error) {
      console.error('contractsDb: delete', error)
    }
  },
  async clear (): Promise<void> {
    try {
      return (await dbPromise).clear('contracts')
    } catch (error) {
      console.error('contractsDb: clear', error)
    }
  },
  async setSorting(id: string, sorting: number) {
    const tx = (await dbPromise).transaction('contracts', 'readwrite')
    try {
      const contract = await tx.store.get(id)
      if (!contract) {
        return
      }
      contract.Sorting = sorting
      tx.store.put(contract)
    } finally {
      tx.done
    }
  }
}

export const draftsDb = {
  async getById (id: string): Promise<FullContract> {
    if (!id) {
      return Promise.resolve(undefined)
    }
    try {
      return (await dbPromise).get('drafts', id)
    } catch (error) {
      console.error('draftsDb: getById', error)
    }
  },
  async getAll (): Promise<FullContract[]> {
    try {
      return (await dbPromise).getAll('drafts')
    } catch (error) {
      console.error('draftsDb: getAll', error)
    }
  },
  async getByType (type: number): Promise<FullContract[]> {
    try {
      return (await dbPromise).getAllFromIndex('drafts', 'by-type', type)
    } catch (error) {
      console.error('draftsDb: getByType', error)
    }
  },
  async setById (contract: FullContract): Promise<string> {
    try {
      return (await dbPromise).put('drafts', cloneDeep(contract))
    } catch (error) {
      console.error('draftsDb: setById', error)
    }
  },
  async delete (id: FullContract['Id']): Promise<void> {
    try {
      return (await dbPromise).delete('drafts', id)
    } catch (error) {
      console.error('draftsDb: delete', error)
    }
  },
  async clear (): Promise<void> {
    try {
      return (await dbPromise).clear('drafts')
    } catch (error) {
      console.error('draftsDb: clear', error)
    }
  }
}

export const queueDb = {
  async getById (id: string): Promise<QueueItem> {
    try {
      return (await dbPromise).get('queue', id)
    } catch (error) {
      console.error('queueDb: getById', error)
    }
  },
  async getAll (): Promise<QueueItem[]> {
    try {
      return (await dbPromise).getAll('queue')
    } catch (error) {
      console.error('queueDb: getAll', error)
    }
  },
  async getKeys (): Promise<string[]> {
    try {
      return (await dbPromise).getAllKeys('queue')
    } catch (error) {
      console.error('queueDb: getKeys', error)
    }
  },
  async setById (item: QueueItem): Promise<string> {
    try {
      return (await dbPromise).put('queue', cloneDeep(item))
    } catch (error) {
      console.error('queueDb: setById', error)
    }
  },
  async delete (id: QueueItem['Id']): Promise<void> {
    try {
      return (await dbPromise).delete('queue', id)
    } catch (error) {
      console.error('queueDb: delete', error)
    }
  },
  async clear (): Promise<void> {
    try {
      return (await dbPromise).clear('queue')
    } catch (error) {
      console.error('queueDb: clear', error)
    }
  }
}

export const customersDb = {
  async getById (id: string): Promise<Customer> {
    try {
      return (await dbPromise).get('customers', id)
    } catch (error) {
      console.error('customersDb: getById', error)
    }
  },
  async getAll (): Promise<Contract[]> {
    try {
      return (await dbPromise).getAll('customers')
    } catch (error) {
      console.error('customersDb: getAll', error)
    }
  },
  async getByName (): Promise<Contract[]> {
    try {
      return (await dbPromise).getAllFromIndex('customers', 'by-name')
    } catch (error) {
      console.error('customersDb: getByName', error)
    }
  },
  async setById (customer: Customer): Promise<string> {
    try {
      return (await dbPromise).put('customers', cloneDeep(customer))
    } catch (error) {
      console.error('customersDb: setById', error)
    }
  },
  async delete (id: Customer['Id']): Promise<void> {
    try {
      return (await dbPromise).delete('customers', id)
    } catch (error) {
      console.error('customersDb: delete', error)
    }
  },
  async clear (): Promise<void> {
    try {
      return (await dbPromise).clear('customers')
    } catch (error) {
      console.error('customersDb: clear', error)
    }
  }
}
