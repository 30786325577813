import Compressor from 'compressorjs/dist/compressor.esm'
import type {Period} from "~/models/period";
import {LOGGING_PERIODS} from "~/use/contracts/loggingperiod/constants";

export function toFixed (value: number, decimals = 2): number {
  return value || value === 0 ? parseFloat(value.toFixed(decimals)) : null
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function toFixedString (value: any, decimals = 2): string {
  return value || value === 0 ? parseFloat(value).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : null
}

export function getMonthName (index: number): string {
  const monthNames = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember']
  // month = Number(month)
  return monthNames[index]
}

export function getMonthFromDate (date: Date): number {
  return new Date(date).getMonth()
}

export function getYearFromDate (date: Date): number {
  return new Date(date).getFullYear()
}

export function updateDateWithYearAndMonth (date: Date, month: number, year: number): Date {
  const _date: Date = new Date(date)
  if (month) { _date.setMonth(month) }
  if (year) { _date.setFullYear(year) }
  return _date
}

export function generateUUID (): string { // Public Domain/MIT
  let d = new Date().getTime()
  const newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })

  return newGuid
}

export function base64ImageSize (base64Image: string): number {
  const stringLength = base64Image.length - 'data:image/jpeg;base64,'.length
  const sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812
  return sizeInBytes
}

export const imageCompressor = (file: File | Blob, quality = 0.8, convertSize = 10000): Promise<Blob> => {
  return new Promise((resolve, reject): void => {
    return new Compressor(file, {
      quality,
      convertSize,
      success (result): void {
        resolve(result)
      },
      error (err): void {
        console.error(err.message)
        reject(err)
      }
    })
  })
}

export const imageToBase64 = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject): void => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (): void => {
      resolve(reader.result as string)
    }
    reader.onerror = reject
  })
}

/**
 * compares two objects, returns the keys of which values are changed
 * @param obj1
 * @param obj2
 * @param prefix
 */
export const compareObjectsGetDifferingKeys = (obj1: any, obj2: any, prefix: string = ''): string[] => {
  // Check if both parameters are objects
  if (obj1 === null || obj1 === undefined || obj2 === null || obj2 === undefined || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2 ? [] : [prefix];
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Initialize an array to store differing keys
  const differingKeys: string[] = [];

  // Iterate over keys and compare values
  for (const key of keys1) {
    // Recursively compare nested objects
    const nestedPrefix = prefix ? `${prefix}.${key}` : key;
    if (!(key in obj2)) {
      differingKeys.push(nestedPrefix);
    } else {
      const nestedDiffKeys = compareObjectsGetDifferingKeys(obj1[key], obj2[key], nestedPrefix);
      differingKeys.push(...nestedDiffKeys);
    }
  }

  // Check for keys present in obj2 but not in obj1
  for (const key of keys2) {
    if (!(key in obj1)) {
      const nestedPrefix = prefix ? `${prefix}.${key}` : key;
      differingKeys.push(nestedPrefix);
    }
  }

  return differingKeys;
}

export const getCurrentYearMonth = (): string => {
  const date = new Date();
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  return year + month;
}

export const getMonthDifference = (date1: string, date2: string): number => {
  const year1 = parseInt(date1.substring(0, 4));
  const month1 = parseInt(date1.substring(4, 6));

  const year2 = parseInt(date2.substring(0, 4));
  const month2 = parseInt(date2.substring(4, 6));

  const diffYears = year2 - year1;
  const diffMonths = month2 - month1;

  return diffYears * 12 + diffMonths;
}

export const getPeriodForMonth = (month: number): number => {
  return LOGGING_PERIODS.find(lp => lp.months.includes(month))?.value!
}

/**
 * Converts a period of the format "YYYYMM" to period object
 * @param stringPeriod
 */
export const convertStringPeriod = (stringPeriod: string): Period => {
  if (stringPeriod?.length !== 6) {
    return {
      Year: -1,
      Period: undefined,
      LoggingMonth: undefined,
    }
  }
  const year = parseInt(stringPeriod.substring(0, 4))
  const loggingMonth = parseInt(stringPeriod.substring(4, 6))

  return {
    Year: year,
    Period: getPeriodForMonth(loggingMonth),
    LoggingMonth: loggingMonth,
  }
}