import type {User} from '@/models/auth'
import { AS_USER_BUSINESS_UNIT_TYPE } from './constants'
import { useLocalstorage } from '~/use/localStorage/store'
import {SessionApi} from "~/gen/openapi/sblService";
import {useAxiosClient} from "~/use/axios/client";

const { persist } = useLocalstorage()
const state = reactive({
  currentUser: persist<User | null>('currentUser', {}),
  error: null as string | null
})

const currentUser = computed((): User | null => state.currentUser)
const userError = computed(() => state.error)
const isAsUser = computed(() => state.currentUser && state.currentUser.BusinessUnitType === AS_USER_BUSINESS_UNIT_TYPE)

function setCurrentUser (user: User): void {
  state.error = null
  state.currentUser = user
}
function clearCurrentUser (): void {
  state.currentUser = null
}
function setError (msg: string): void {
  state.error = msg
}

async function loadUser (): Promise<User> {
  try {
    const { axiosClient } = useAxiosClient()
    const sessionApi = new SessionApi(undefined, '', axiosClient.value)
    const user: User = (await sessionApi.sessionGetUserInfo())?.data
    console.log('User:', user);
    if (user) {
      (window as any).localStorage.setItem('user', JSON.stringify(user))
      setCurrentUser(user)
      return user
    }
    return Promise.reject('No user found')
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.Message) {
      setError(e.response.data.Message)
    }
    return Promise.reject(e)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUserStore = () => ({
  currentUser,
  clearCurrentUser,
  isAsUser,
  loadUser,
  setCurrentUser,
  setError,
  userError
})
