// Relevant signature type codes. Other options are
// portal - 1
// email - 2
// paper - 3
// oral agreement - 5

import {featureEnabled} from '~/helpers/features';

export const CONTRACT_SIGNATURE_TYPE_CODE_TABLET = 4
export const CONTRACT_SIGNATURE_TYPE_CODE_BANKID = 6

export const PRICE_LIST_ALLOCATION_CODE_DYNAMIC = 1
export const PRICE_LIST_ALLOCATION_CODE_STATIC = 2

export const MIN_FORESTFUND_RATE = 4
export const MAX_FORESTFUND_RATE = 40

export const CONTRACT_FIELD_COLLECTIONPOINT_LENGT_MIN = 3

export const CONTRACT_TYPE_FOREST_OPERATION = 1
export const CONTRACT_TYPE_SIVIL_CULTURE = 2
export const CONTRACT_TYPE_REGISTRATION = 3
export const CONTRACT_TYPE_SUPPLY_WOOD = 4

export const CONTRACT_TYPES = [
  {
    Id: CONTRACT_TYPE_FOREST_OPERATION,
    Name: 'TYPE_FOREST_OPERATION'
  },
  {
    Id: CONTRACT_TYPE_SIVIL_CULTURE,
    Name: 'TYPE_SIVIL_CULTURE'
  },
  {
    Id: CONTRACT_TYPE_REGISTRATION,
    Name: 'TYPE_REGISTRATION'
  },
  {
    Id: CONTRACT_TYPE_SUPPLY_WOOD,
    Name: 'TYPE_SUPPLY_WOOD'
  }
]

export const CONTRACT_TYPES_ICONS = [
  {
    Id: CONTRACT_TYPE_FOREST_OPERATION,
    Name: 'spot/felling'
  },
  {
    Id: CONTRACT_TYPE_SIVIL_CULTURE,
    Name: 'spot/large/silviculture'
  },
  {
    Id: CONTRACT_TYPE_REGISTRATION,
    Name: 'spot/felling'
  },
  {
    Id: CONTRACT_TYPE_SUPPLY_WOOD,
    Name: 'spot/felling'
  }
]

export const CONTRACT_PERIODS = [
  {
    Id: 1,
    Name: 'PERIOD_WINTER'
  },
  {
    Id: 2,
    Name: 'PERIOD_SPRING'
  },
  {
    Id: 3,
    Name: 'PERIOD_SUMMER'
  },
  {
    Id: 4,
    Name: 'PERIOD_FALL'
  }
]

export const CONTRACT_COMPLETIONS = [
  {
    Id: 1,
    Name: 'COMPLETION_AGREED_VOLUME'
  },
  {
    Id: 2,
    Name: 'COMPLETION_AGREED_AREA'
  }
]

export const CONTRACT_OPERATION_COST_TYPE = [
  {
    value: 1,
    text: 'OPERATION_PRICE_FIXED_PRICE_SHORT',
    subText: 'KR_M_UNDER_BARK',
    key: '1'
  },
  {
    value: 2,
    text: 'OPERATION_PRICE_SUGGESTED_RETAIL_SHORT',
    subText: 'KR_M_UNDER_BARK',
    key: '2'
  },
  {
    value: 3,
    text: 'HOURLY_ASSIGNMENTS',
    key: '3'
  }
]

export const CONTRACT_DETAIL_PRICE_NOT_SHAREHOLDER = 0
export const CONTRACT_DETAIL_PRICE_SHAREHOLDER = 1
export const CONTRACT_DETAIL_PRICE_10 = 10
export const CONTRACT_DETAIL_PRICE_20 = 20
export const CONTRACT_DETAIL_PRICE_30 = 30
export const CONTRACT_DETAIL_PRICE_40 = 40
export const CONTRACT_DETAIL_PRICE_MANUAL = 99

export const CONTRACT_DETAIL_PRICES = [
  {
    Id: CONTRACT_DETAIL_PRICE_NOT_SHAREHOLDER,
    Name: 'Ikke andelseier'
  },
  {
    Id: CONTRACT_DETAIL_PRICE_SHAREHOLDER,
    Name: 'Standard andelseier'
  },
  {
    Id: CONTRACT_DETAIL_PRICE_10,
    Name: '+ 10'
  },
  {
    Id: CONTRACT_DETAIL_PRICE_20,
    Name: '+ 20'
  },
  {
    Id: CONTRACT_DETAIL_PRICE_30,
    Name: '+ 30'
  },
  {
    Id: CONTRACT_DETAIL_PRICE_40,
    Name: '+ 40'
  },
  {
    Id: CONTRACT_DETAIL_PRICE_MANUAL,
    Name: 'Annet'
  }
]

const featureEnvironmentReportForSupplyWoodEnabled = featureEnabled(import.meta.env.FEATURE_ENVIRONMENT_REPORT_FOR_SUPPLY_WOOD)
export const CONTRACT_TYPE_STEPS = [
  {
    Type: CONTRACT_TYPE_FOREST_OPERATION,
    Steps: [
      {
        Step: 1,
        Page: 'loggingperiod',
        Title: 'LOGGING_PERIOD'
      }, {
        Step: 2,
        Page: 'actionplans',
        Title: 'ACTIONPLANS_TITLE'
      }, {
        Step: 3,
        Page: 'environmentreport',
        Title: 'ENVIRONMENT_REPORT'
      }, {
        Step: 4,
        Page: 'additions',
        Title: 'ADDITIONS'
      }, {
        Step: 5,
        Page: 'volumeAndEntrepreneur',
        Title: 'VOLUME_ENTREPRENEUR'
      }, {
        Step: 6,
        Page: 'assortments',
        Title: 'ASSORTMENTS'
      }, {
        Step: 7,
        Page: 'prices',
        Title: 'PRICES'
      }, {
        Step: 8,
        Page: 'toppleplaces',
        Title: 'COLLECTION_PLACE'
      }, {
        Step: 9,
        Page: 'agreement',
        Title: 'FOREST_REMOVAL_MISSION'
      }, {
        Step: 10,
        Page: 'silviculture',
        Title: 'SILVICULTURE'
      }, {
        Step: 11,
        Page: 'finaldetails',
        Title: 'FINAL_DETAILS'
      }, {
        Step: 12,
        Page: 'summary',
        Title: 'SUMMARY'
      }, {
        Step: 13,
        Page: 'details',
        Title: 'DETAILS'
      }, {
        Step: 14,
        Page: 'sign',
        Title: 'SIGN'
      }, {
        Step: 15,
        Page: 'finish',
        Title: 'FINISH'
      }
    ]
  },
  {
    Type: CONTRACT_TYPE_SIVIL_CULTURE,
    Steps: [
      {
        Step: 1,
        Page: 'actionplans',
        Title: 'ACTIONPLANS_TITLE'
      }, {
        Step: 2,
        Page: 'silviculture',
        Title: 'SILVICULTURE'
      }, {
        Step: 3,
        Page: 'finaldetails',
        Title: 'FINAL_DETAILS'
      }, {
        Step: 4,
        Page: 'details',
        Title: 'DETAILS'
      }
    ]
  },
  {
    Type: CONTRACT_TYPE_REGISTRATION,
    Steps: [
      {
        Step: 1,
        Page: 'loggingperiod',
        Title: 'LOGGING_PERIOD'
      }, {
        Step: 2,
        Page: 'volumeAndEntrepreneur',
        Title: 'VOLUME_ENTREPRENEUR'
      }, {
        Step: 3,
        Page: 'assortments',
        Title: 'ASSORTMENTS'
      }, {
        Step: 4,
        Page: 'prices',
        Title: 'PRICES'
      }, {
        Step: 5,
        Page: 'toppleplaces',
        Title: 'COLLECTION_PLACE'
      }, {
        Step: 6,
        Page: 'agreement',
        Title: 'FOREST_REMOVAL_MISSION'
      }, {
        Step: 7,
        Page: 'finaldetails',
        Title: 'FINAL_DETAILS'
      }, {
        Step: 8,
        Page: 'details',
        Title: 'DETAILS'
      }
    ]
  },
  {
    Type: CONTRACT_TYPE_SUPPLY_WOOD,
    Steps: [
      {
        Step: 1,
        Page: 'loggingperiod',
        Title: 'LOGGING_PERIOD'
      }, {
        Step: 2,
        Page: 'actionplans',
        Title: 'ACTIONPLANS_TITLE'
      }, {
        Step: 3,
        Page: featureEnvironmentReportForSupplyWoodEnabled ? 'environmentreport' : 'environmentcheck',
        Title: 'DUE_DILIGENCE_ASSESSMENT'
      }, {
        Step: 4,
        Page: 'volumeAndEntrepreneur',
        Title: 'VOLUME_ENTREPRENEUR'
      }, {
        Step: 5,
        Page: 'assortments',
        Title: 'ASSORTMENTS'
      }, {
        Step: 6,
        Page: 'prices',
        Title: 'PRICES'
      }, {
        Step: 7,
        Page: 'toppleplaces',
        Title: 'COLLECTION_PLACE'
      }, {
        Step: 8,
        Page: 'agreement',
        Title: 'FOREST_REMOVAL_MISSION'
      }, {
        Step: 9,
        Page: 'silviculture',
        Title: 'SILVICULTURE'
      }, {
        Step: 10,
        Page: 'finaldetails',
        Title: 'FINAL_DETAILS'
      }, {
        Step: 11,
        Page: 'summary',
        Title: 'SUMMARY'
      }, {
        Step: 12,
        Page: 'sign',
        Title: 'SIGN'
      }, {
        Step: 13,
        Page: 'finish',
        Title: 'FINISH'
      }
    ]
  }
]

export const NEW_CONTRACT_OPTIONS = [
  {
    key: 'forestoperation',
    value: CONTRACT_TYPE_FOREST_OPERATION,
    text: 'TYPE_FOREST_OPERATION',
    subText: 'TYPE_FOREST_OPERATION_DESC',
    icon: 'spot/large/felling'
  }, {
    key: 'supplywood',
    value: CONTRACT_TYPE_SUPPLY_WOOD,
    text: 'TYPE_SUPPLY_WOOD',
    subText: 'TYPE_SUPPLY_WOOD_DESC',
    icon: 'spot/large/mill'
  }, {
    key: 'sivilculture',
    value: CONTRACT_TYPE_SIVIL_CULTURE,
    text: 'TYPE_SIVIL_CULTURE_ORDER',
    subText: 'TYPE_SIVIL_CULTURE_ORDER_DESC',
    icon: 'spot/large/silviculture'
  }, {
    key: 'registration',
    value: CONTRACT_TYPE_REGISTRATION,
    text: 'TYPE_REGISTRATION',
    subText: 'TYPE_REGISTRATION_DESC',
    icon: 'spot/large/transport'
  },
]

export const NEW_CONTRACT_TYPE_OPTIONS = [
  {
    key: 'forestoperation',
    value: CONTRACT_TYPE_FOREST_OPERATION,
    text: 'Vanlig driftsavtale',
    subText: 'Glommen Mjøsen håndterer drift, sertifisering og foryngelse'
  }, {
    key: 'registration',
    value: CONTRACT_TYPE_REGISTRATION,
    text: 'Innmelding',
    subText: 'Glommen Mjøsen håndterer transport og salg'
  }, {
    key: 'supplywood',
    value: CONTRACT_TYPE_SUPPLY_WOOD,
    text: 'Leveransevirke',
    subText: 'Glommen Mjøsen håndterer salg'
  }
]

export const SIVIL_CULTURE = [
  {
    Name: 'IS_GROUND_PREPARED',
    Field: 'IsGroundPrepared',
    Required: false
  },
  {
    Name: 'SKID_ROAD',
    Field: 'SkidRoad',
    Required: false
  },
  {
    Name: 'ENVIRONMENTAL_CONDITIONS',
    Field: 'EnvironmentalConditions',
    Required: false
  },
  {
    Name: 'OPERATION_ROAD_LENGTH',
    Field: 'OperationRoadLength500',
    Required: false
  },
  {
    Name: 'FALL_PLANTING',
    Field: 'FallPlanting',
    Required: false
  },
  {
    Name: 'BARRIER_KEYS',
    Field: 'BarrierKeys',
    Required: false
  },
  {
    Name: 'FOREST_OWNER_TRANSPORTS_PLANTS',
    Field: 'ForestOwnerTransportsPlants',
    Required: false
  },
  {
    Name: 'NEED_YOUNG_FOREST_CARE_ASSESSED_ON_PROPERTY',
    Field: 'NeedYoungForestCareAssessedOnProperty',
    Required: true,
    Group: 'YOUNG_FOREST_CARE'
  },
  {
    Name: 'NEED_YOUNG_FOREST_CARE',
    Field: 'NeedYoungForestCare',
    Required: true,
    Group: 'YOUNG_FOREST_CARE'
  },
  {
    Name: 'PLAN_IMPLEMENTATION_OF_YOUNG_FOREST_CARE',
    Field: 'PlanImplementationOfYoungForestCare',
    Required: true,
    Group: 'YOUNG_FOREST_CARE'
  }
]

/**
 * Hack for https://dev.azure.com/GlommenMjosen/Portaler%20og%20SBL-app/_workitems/edit/1641
 */
export const TIME_TO_WAIT_BEFORE_ACTIVATION = 1000 * 5


/**
 * Contract activation wait time after making changes that need syncing
 */
export const MS_DELAY_CRM_TO_VH_SYNC = 1000 /*ms*/ * 90 /*seconds*/

/** Interval for running cleanup-routine, clearing old entries */
export const MS_INTERVAL_CLEAN_MODIFIED_TIMESTAMPS = 1000 /*ms*/ * 60 /*seconds*/ * 5 /*minutes*/

