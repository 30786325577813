
export enum RequestType {
  'get',
  'post',
  'put',
  'delete',
}

export enum DataType {
  'Contract',
  'Customer',
  'EnvironmentalAgreement',
  'EnvironmentReport',
  'Incident',
  'InternalControl',
  'UserNote',
}

export interface QueueItem {
  date: Date;
  Id: string;
  payload: any;
  requestType: RequestType | string;
  title?: string;
  type: DataType;
  url: string;
}
