import type {AxiosResponse} from 'axios'
import { generateUUID } from '~/helpers/util'
import { notify } from '@gm/components'

import { customersDb, queueDb } from '@/helpers/indexDB'
import { getContractCustomer } from '@/helpers/contractHelper'
import { RequestType, DataType } from '@/models/queue'
import { useCustomersStore } from '@/use/customers/store'
import { useQueueSizeStore } from '@/use/ui/queueSize'
import { useLocalstorage } from '../localStorage/store'
import { setDefaultEnvironmentalAgreementData } from './util'
import type {EnvironmentalAgreement} from '~/models/environmentalAgreement'
import type {Customer} from '~/models/customers'
import {useAxiosClient} from "~/use/axios/client";
import {EnvironmentalAgreementApi} from "~/gen/openapi/sblService";

const { currentCustomer, saveCurrentCustomer } = useCustomersStore()
const { updateQueueSize } = useQueueSizeStore()

const { persist } = useLocalstorage()

const state = reactive({
  currentEnvironmentalAgreement: persist<EnvironmentalAgreement>('currentEnvironmentalAgreement', {})
})

const currentEnvironmentalAgreement = computed((): EnvironmentalAgreement => state.currentEnvironmentalAgreement)

const setCurrentEnvironmentalAgreement = (environmentalAgreement: EnvironmentalAgreement): void => {
  state.currentEnvironmentalAgreement = environmentalAgreement
}
const clearCurrentEnvironmentalAgreement = (): void => {
  state.currentEnvironmentalAgreement = {}
}

const isTempAgreement = (id: string) => {
  return id.match(/^temp_.+/)
}

const loadEnvironmentalAgreement = async (id: string, customer: Customer = currentCustomer.value): Promise<void> => {
  // If temp id create new standard agreement
  // Otherwise fetch from api
  if (isTempAgreement(id)) {
    try {
      setCurrentEnvironmentalAgreement(setDefaultEnvironmentalAgreementData(customer))
    } catch (error) {
      notify(error.toString(), {
        type: 'error',
        icon: 'exclamation'
      })
      throw error
    }
  } else {
    try {
      const { axiosClient } = useAxiosClient()
      const environmentalAgreementApi = new EnvironmentalAgreementApi(undefined, '', axiosClient.value)
      const environmentalAgreement: EnvironmentalAgreement = (await environmentalAgreementApi.environmentalAgreementGetEnvironmentalAgreement(id))?.data
      setCurrentEnvironmentalAgreement(environmentalAgreement)
    } catch (error) {
      const responseError = error as AxiosResponse
      notify(`${responseError.status}: ${responseError.data.Message}`, {
        type: 'error',
        icon: 'exclamation'
      })

      throw error
    }
  }
}

const saveEnvironmentalAgreement = async (agreement: EnvironmentalAgreement): Promise<EnvironmentalAgreement> => {
  const baseUrl = '/v1/environmentalagreements'
  let url: string
  let requestType: RequestType

  if (!agreement.Id || agreement.Id?.includes('temp_')) {
    delete agreement.Id
    url = baseUrl
    requestType = RequestType.post
  } else {
    url = `${baseUrl}/${agreement.Id}`
    requestType = RequestType.put
  }

  try {
    const { axiosClient } = useAxiosClient()
    const environmentalAgreementApi = new EnvironmentalAgreementApi(undefined, '', axiosClient.value)
    let response: EnvironmentalAgreement
    if (!agreement.Id || agreement.Id?.includes('temp_')) {
      delete agreement.Id
      response = (await environmentalAgreementApi.environmentalAgreementCreateEnvironmentalAgreement(agreement))?.data
    } else {
      response = (await environmentalAgreementApi.environmentalAgreementUpdateEnvironmentalAgreement(agreement.Id, agreement))?.data
    }


    const customer = await customersDb.getById(response.ForestOwnerId)
    customer.EnvironmentalAgreement = response
    await customersDb.setById(customer)
    await saveCurrentCustomer(customer)
    return agreement
  } catch (error) {
    const customer = await getContractCustomer(agreement)
    await queueDb.setById({
      Id: generateUUID(),
      url,
      date: new Date(),
      requestType,
      payload: agreement,
      type: DataType.EnvironmentalAgreement,
      title: customer.Name
    })
    await updateQueueSize()
    notify('Legger avtale i kø', {
      type: 'info'
    })
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useEnvironmentalAgreementsStore = () => ({
  currentEnvironmentalAgreement,
  saveEnvironmentalAgreement,
  setCurrentEnvironmentalAgreement,
  clearCurrentEnvironmentalAgreement,
  loadEnvironmentalAgreement
})
