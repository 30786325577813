import { CONTRACT_TYPE_SIVIL_CULTURE, CONTRACT_TYPE_FOREST_OPERATION, CONTRACT_TYPE_SUPPLY_WOOD } from '~/use/contracts/constants'

export const ALLMA_JOB_TYPE_PRECLEARING_FELLING = '1'
export const ALLMA_JOB_TYPE_PRECLEARING_THINNING = '2'
export const ALLMA_JOB_TYPE_FERTILIZATION = '3'
export const ALLMA_JOB_TYPE_DITCH_CLEARING = '4'
export const ALLMA_JOB_TYPE_HOGGER = '5'
export const ALLMA_JOB_TYPE_SCARIFICATION = '6'
export const ALLMA_JOB_TYPE_SCARIFICATION_WITH_EXCAVATOR = '7'
export const ALLMA_JOB_TYPE_PLANTATION_CONTROL = '8'
export const ALLMA_JOB_TYPE_PLANTING = '9'
export const ALLMA_JOB_TYPE_TRACK_REMOVING = '10'
export const ALLMA_JOB_TYPE_SPRAYING = '11'
export const ALLMA_JOB_TYPE_STEMPRUNING = '12'
export const ALLMA_JOB_TYPE_ADDITIVE_PLANTING = '13'
export const ALLMA_JOB_TYPE_THINNING = '14'
export const ALLMA_JOB_TYPE_YOUNG_TREE_CARE = '15'
export const ALLMA_JOB_TYPE_SCARIFICATION_WITH_SOWING = '16'

const ALL_MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export interface AllmaJobType {
  TypeId: string,
  TypeName?: string,
  HasSubTypes: boolean,
  SelectOptions?: Array<{Id: number, Name: string, AllowedMonths: Array<number>}>
  AllowedMonths: Array<number>
}

export const EXCLUDE_ALLMA_JOB_TYPES = [
  {
    contractType: CONTRACT_TYPE_SIVIL_CULTURE,
    jobTypes: [ALLMA_JOB_TYPE_HOGGER, ALLMA_JOB_TYPE_TRACK_REMOVING, ALLMA_JOB_TYPE_THINNING]
  },
  {
    contractType: CONTRACT_TYPE_FOREST_OPERATION,
    jobTypes: [ALLMA_JOB_TYPE_TRACK_REMOVING]
  },
  {
    contractType: CONTRACT_TYPE_SUPPLY_WOOD,
    jobTypes: [ALLMA_JOB_TYPE_TRACK_REMOVING]
  }
]

export const ALLMA_JOB_TYPES: Array<AllmaJobType> = [
  {
    TypeId: ALLMA_JOB_TYPE_PLANTING,
    TypeName: 'PLANTING',
    HasSubTypes: true,
    SelectOptions: [
      {
        Id: 1,
        Name: 'PLANTING',
        AllowedMonths: [4, 8]
      },
      {
        Id: 2,
        Name: 'PLANTSALE',
        AllowedMonths: [4, 8]
      }
    ],
    AllowedMonths: [4, 8]
  },
  {
    TypeId: ALLMA_JOB_TYPE_PLANTATION_CONTROL,
    TypeName: 'PLANTATION_CONTROL',
    HasSubTypes: false,
    AllowedMonths: ALL_MONTHS
  },
  {
    TypeId: ALLMA_JOB_TYPE_SCARIFICATION,
    TypeName: 'SCARIFICATION',
    HasSubTypes: false,
    AllowedMonths: [6, 8]
  },
  {
    TypeId: ALLMA_JOB_TYPE_SCARIFICATION_WITH_EXCAVATOR,
    TypeName: 'SCARIFICATION_WITH_EXCAVATOR',
    HasSubTypes: false,
    AllowedMonths: [5, 6, 7, 8, 9]
  },
  {
    TypeId: ALLMA_JOB_TYPE_SCARIFICATION_WITH_SOWING,
    TypeName: 'SCARIFICATION_WITH_SOWING',
    HasSubTypes: false,
    AllowedMonths: [5, 6, 8]
  },
  {
    TypeId: ALLMA_JOB_TYPE_DITCH_CLEARING,
    TypeName: 'DITCH_CLEARING',
    HasSubTypes: false,
    AllowedMonths: ALL_MONTHS
  },
  {
    TypeId: ALLMA_JOB_TYPE_YOUNG_TREE_CARE,
    TypeName: 'YOUNG_TREE_CARE',
    HasSubTypes: false,
    AllowedMonths: ALL_MONTHS
  },
  {
    TypeId: ALLMA_JOB_TYPE_SPRAYING,
    TypeName: 'SPRAYING',
    HasSubTypes: false,
    AllowedMonths: ALL_MONTHS
  },
  {
    TypeId: ALLMA_JOB_TYPE_FERTILIZATION,
    TypeName: 'FERTILIZATION',
    HasSubTypes: false,
    AllowedMonths: ALL_MONTHS
  },
  {
    TypeId: ALLMA_JOB_TYPE_STEMPRUNING,
    TypeName: 'STEMPRUNING',
    HasSubTypes: false,
    AllowedMonths: ALL_MONTHS
  },
  {
    TypeId: ALLMA_JOB_TYPE_ADDITIVE_PLANTING,
    TypeName: 'ADDITIVE_PLANTING',
    HasSubTypes: false,
    AllowedMonths: ALL_MONTHS
  },
  {
    TypeId: ALLMA_JOB_TYPE_PRECLEARING_FELLING,
    TypeName: 'PRECLEARING_FELLING',
    HasSubTypes: false,
    AllowedMonths: ALL_MONTHS
  },
  {
    TypeId: ALLMA_JOB_TYPE_PRECLEARING_THINNING,
    TypeName: 'PRECLEARING_THINNING',
    HasSubTypes: false,
    AllowedMonths: ALL_MONTHS
  },
  {
    TypeId: ALLMA_JOB_TYPE_TRACK_REMOVING,
    TypeName: 'TRACK_REMOVING',
    HasSubTypes: false,
    AllowedMonths: ALL_MONTHS
  },
  {
    TypeId: ALLMA_JOB_TYPE_HOGGER,
    TypeName: 'HOGGER',
    HasSubTypes: false,
    AllowedMonths: ALL_MONTHS
  },
  {
    TypeId: ALLMA_JOB_TYPE_THINNING,
    TypeName: 'THINNING',
    HasSubTypes: false,
    AllowedMonths: ALL_MONTHS
  }
]
