
import { useLocalstorage } from '../localStorage/store'
import {useCustomersDb} from '~/use/indexedDb/customersDb';
import type {Customer} from '~/models/customers';
import cloneDeep from 'lodash/cloneDeep';
import {notify} from "@gm/components";
import {useAxiosClient} from "~/use/axios/client";
import {CustomerApi} from "~/gen/openapi/sblService";

const { insertCustomers, customersDb } = useCustomersDb()
const { persist } = useLocalstorage()

const state = reactive({
  currentCustomer: persist('currentCustomer', {} as Customer),
  lastUpdated: persist('lastUpdatedCustomers', null as unknown as Date),
  favorites: persist('favoriteCustomers', [] as Array<Customer['Id']>),
  searchTerm: '',
  customerViewExpanded: persist('customerViewExpanded', false)
})
const searchTerm = ref(state.searchTerm)

const customerViewExpanded = computed((): boolean => state.customerViewExpanded)
const currentCustomer = computed((): Customer => state.currentCustomer)
const lastUpdatedCustomers = computed((): Date => state.lastUpdated)
const favoriteIds = computed((): Array<Customer['Id']> => state.favorites)

const setCurrentCustomer = (customer: Customer): void => {
  state.currentCustomer = customer
}
const clearCurrentCustomer = (): void => {
  state.currentCustomer = {}
}
const setLastUpdated = (): void => {
  state.lastUpdated = new Date()
}
const addFavorite = (id: Customer['Id']): void => {
  state.favorites.push(id)
}
const removeFavorite = (id: Customer['Id']): void => {
  state.favorites = state.favorites.filter(i => i !== id)
}

const setCustomerViewExpanded = (isExpanded: boolean): void => {
  state.customerViewExpanded = isExpanded
}

const loadCustomers = async (): Promise<void> => {
  const { axiosClient } = useAxiosClient()
  const customerApi = new CustomerApi(undefined, '', axiosClient.value)
  const customers: Customer[] = (await customerApi.customerGetCustomersV2())?.data
  await insertCustomers(customers)
  setLastUpdated()
}
const loadIncrementalCustomers = async (): Promise<boolean> => {
  const { axiosClient } = useAxiosClient()
  const customerApi = new CustomerApi(undefined, '', axiosClient.value)
  const customers: Customer[] = (await customerApi.customerGetCustomersV2({params: {lastUpdated: state.lastUpdated}}))?.data
  await insertCustomers(customers)
  setLastUpdated()
  return true
}

const loadCustomer = async (id: string): Promise<Customer | void> => {
  const { axiosClient } = useAxiosClient()
  const customerApi = new CustomerApi(undefined, '', axiosClient.value)
  const response = (await customerApi.customerGetCustomerV2(id))?.data
  if (response?.length === 1) {
    const customer: Customer = response[0]
    await customersDb.setById(customer)
    return customer
  }
  throw new Error('GET customer returned more than one results')
}

const saveCurrentCustomerLocally = (customer: Customer) => {
  customersDb.setById(cloneDeep(customer))
  setCurrentCustomer(customer)
}

const saveCurrentCustomer = async (customer: Customer): Promise<void> => {
  try {
    const { axiosClient } = useAxiosClient()
    const customerApi = new CustomerApi(undefined, '', axiosClient.value)
    const response = (await customerApi.customerUpdateCustomer(customer.Id, customer))?.data
    saveCurrentCustomerLocally(response)
  } catch (error: any) {
    console.error(error)
    notify(error.toString(), {
      type: 'error',
      icon: 'exclamation',
      closeable: true
    })
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCustomersStore = () => ({
  addFavorite,
  clearCurrentCustomer,
  currentCustomer,
  customerViewExpanded,
  favoriteIds,
  lastUpdatedCustomers,
  loadCustomers,
  loadIncrementalCustomers,
  loadCustomer,
  removeFavorite,
  saveCurrentCustomer,
  saveCurrentCustomerLocally,
  searchTerm,
  setCurrentCustomer,
  setCustomerViewExpanded
})
