
import { useAxiosClient } from '~/use/axios/client'
import Axios from 'axios';
import {
  BrowserAuthError,
  CacheLookupPolicy,
  InteractionRequiredAuthError,
  PublicClientApplication
} from '@azure/msal-browser';

export default defineNuxtPlugin((nuxtApp): void => {
  const { setClient } = useAxiosClient()
  const $msal = nuxtApp.$msal as PublicClientApplication
  console.log('auth: ', $msal)

  const $axios = Axios.create({
    baseURL: nuxtApp.$config.public.API_URL,
    headers: {
      common: {
        'Content-Type': 'application/json',
      }
    }
  })

  $axios.interceptors.request.use(async (config) => {
    const account = $msal.getAllAccounts()[0];
    if (!account) {
      throw new Error('No account found');
    }

    const silentRequest = {
      account: account,
      scopes: ['openid', 'profile', 'api://e5f8da5a-397b-426d-a8d6-540c61993e91/user_impersonation'],
      redirectUri: nuxtApp.$config.public.AUTH_REDIRECT_URL, // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#block_iframe_reload
      forceRefresh: false,
      cacheLookupPolicy: CacheLookupPolicy.Default,
      refreshTokenExpirationOffsetSeconds: 7200 // refresh before actual expiry, 2 hours * 60 minutes * 60 seconds = 7200 seconds
    };

    const request = {
      scopes: ['openid', 'profile', 'api://e5f8da5a-397b-426d-a8d6-540c61993e91/user_impersonation'],
      account: account,
      loginHint: account.username
    };

    await $msal.acquireTokenSilent(silentRequest).then((result) => {
      config.headers.setAuthorization(`Bearer ${result.accessToken}`)
    }).catch(async (error) => {
      if (error.name === 'InteractionRequiredAuthError' || error instanceof InteractionRequiredAuthError) {
        await $msal.acquireTokenRedirect(request)  // fallback to interaction when silent call fails
      } else {
        return Promise.reject(error)
      }
    })

    return config
  }, (error) => {
    return Promise.reject(error)
  })

  $axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        // prevent the console being flooded with long error messages
        if (error instanceof BrowserAuthError && error.errorCode === 'interaction_in_progress') {
          return Promise.reject('Axios error, awaiting user to complete authentication (interaction_in_progress)')
        } else {
          return Promise.reject(error)
        }
      }
  );

  setClient($axios)
})
