import {
  getCurrentScope,
  init,
  captureConsoleIntegration, browserTracingIntegration, replayIntegration
} from '@sentry/vue'
import { type User } from '@/models/auth'
import { useRouter } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  if (nuxtApp.$config.public.environment !== 'DEV') {
    console.log('Sentry is active')
    const router = useRouter()
    init({
      app: nuxtApp.vueApp,
      dsn: nuxtApp.$config.public.sentryDsn,
      integrations: [
          captureConsoleIntegration({
            levels: ['error']
          }),
        browserTracingIntegration({
          router: router
          }),
          // TODO: 22/08/2023 - assess if we want to keep this
        replayIntegration(),
      ],
      ignoreErrors: [
        "Auth error, method:",
        "Error: Request failed with status code 401",
        "interaction_in_progress"
      ],
      environment: nuxtApp.$config.public.environment,
      release: `${nuxtApp.$config.public.version}:${nuxtApp.$config.public.environment}`,
      tracesSampleRate: 0.6,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })

    {
      const scope = getCurrentScope();
      try {
        const user: User = JSON.parse(
            (window as any).localStorage.getItem('user')
        )
        if (user) {
          scope.setUser({
            email: user.Email,
            id: user.Id,
            username: user.Name
          })
        }
      } catch (e) {
        console.warn('Error configuring sentry scope', e)
      }
    }
  }
})
