// https://router.vuejs.org/guide/migration/#removal-of-append-prop-in-router-link
export default defineNuxtPlugin((nuxtApp) => {
    const append = (path: string, pathToAppend: string) =>
        path + (path.endsWith('/') ? '' : '/') + pathToAppend

    return {
        provide: {
            append
        }
    }
})
